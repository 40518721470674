import React, { useState, useEffect } from 'react';
import { categoryService } from '../../api/categoryService';
import { SearchableCategorySelect } from './SearchableCategorySelect';
import './CategorySelector.scss';

/**
 * CategorySelector component for hierarchical category selection
 * Supports main categories, subcategories, and multiple tags
 */
export const CategorySelector = ({
  onCategorySelect,
  onSubcategorySelect,
  onTagSelect,
  selectedCategory,
  selectedSubcategories,
  selectedTags,
  disabled = false
}) => {
  const [subcategories, setSubcategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Fetch subcategories when a main category is selected
  useEffect(() => {
    const fetchSubcategories = async () => {
      if (!selectedCategory) {
        setSubcategories([]);
        return;
      }
      
      try {
        setLoading(true);
        setError(null);
        const response = await categoryService.getSubcategoriesByCategoryId(selectedCategory);
        const subcategoriesData = response.results || [];
        setSubcategories(subcategoriesData);
      } catch (err) {
        console.error('Error fetching subcategories:', err);
        setError('Failed to load subcategories. Please try again.');
        setSubcategories([]);
      } finally {
        setLoading(false);
      }
    };
    
    fetchSubcategories();
  }, [selectedCategory]);
  
  // Fetch tags when subcategories are selected
  useEffect(() => {
    const fetchTags = async () => {
      if (!selectedCategory || !selectedSubcategories.length) {
        setTags([]);
        return;
      }
      
      try {
        setLoading(true);
        setError(null);
        
        const response = await categoryService.getAllTags({
          categoryId: selectedCategory,
          subcategoryId: selectedSubcategories[0]
        });
        
        setTags(response.results || []);
      } catch (err) {
        console.error('Error fetching tags:', err);
        setError('Failed to load tags. Please try again.');
        setTags([]);
      } finally {
        setLoading(false);
      }
    };
    
    fetchTags();
  }, [selectedCategory, selectedSubcategories]);

  const handleCategoryChange = (category) => {
    onCategorySelect(category?.id || null);
    onSubcategorySelect([]);
    onTagSelect([]);
  };

  const handleSubcategoryChange = (e) => {
    const value = e.target.value;
    onSubcategorySelect(value ? [value] : []);
    onTagSelect([]);
  };

  const handleTagChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    onTagSelect(selectedOptions.filter(Boolean));
  };

  return (
    <div className="category-selector">
      <div className="category-selector__section">
        <SearchableCategorySelect
          value={selectedCategory}
          onChange={handleCategoryChange}
          error={error}
          disabled={disabled}
          required
        />
        {error && <div className="category-selector__error">{error}</div>}
      </div>

      {selectedCategory && (
        <div className="category-selector__section">
          <select
            className="category-selector__select"
            value={selectedSubcategories[0] || ''}
            onChange={handleSubcategoryChange}
            disabled={disabled}
          >
            <option value="">Select Subcategory</option>
            {loading ? (
              <option value="" disabled>Loading subcategories...</option>
            ) : subcategories.length > 0 ? (
              subcategories.map((subcategory) => (
                <option key={subcategory.id} value={subcategory.id}>
                  {subcategory.name}
                </option>
              ))
            ) : (
              <option value="" disabled>No subcategories available</option>
            )}
          </select>
          {loading && <div className="category-selector__loading">Loading subcategories...</div>}
          {error && <div className="category-selector__error">{error}</div>}
        </div>
      )}

      {selectedSubcategories.length > 0 && selectedSubcategories[0] && (
        <div className="category-selector__section">
          <label className="category-selector__label">Tags (Select multiple)</label>
          <select
            className="category-selector__select"
            multiple
            value={selectedTags}
            onChange={handleTagChange}
            disabled={disabled || loading}
            size={5}
          >
            {loading ? (
              <option disabled>Loading tags...</option>
            ) : tags.length > 0 ? (
              tags.map((tag) => (
                <option key={tag.id} value={tag.id}>
                  {tag.name}
                </option>
              ))
            ) : (
              <option disabled>No tags available</option>
            )}
          </select>
          {error && <div className="category-selector__error">{error}</div>}
        </div>
      )}
    </div>
  );
};

export default CategorySelector;