import './Banner.scss'
import {MeetExpertForm} from '../../../components/ExpertForm/ExpertForm'
import { useState } from 'react';

const BANNER_TITLES = {
  seller: {
    lines: ['Get Seen.', 'Get Connected.', 'Grow Your Business.']
  },
  buyer: {
    lines: ['Find Products.', 'Connect with Brands.', 'Grow Your Portfolio.']
  }
};

export const Banner = ({ selected, setIsRegisterFormVisible }) => {
  const [isMeetExpertFormVisible, setIsMeetExpertFormVisible] = useState(false);

  const handleStartNow = () => {
    setIsRegisterFormVisible(true);
  };

  const handleMeetExpert = () => {
    setIsMeetExpertFormVisible(true);
  };

  return (
    <div className="Banner">
      <h1 className='Banner__title'>
        {BANNER_TITLES[selected].lines.map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </h1>
      <p className='Banner__p'>
        The premier marketplace connecting alcohol brands and resellers—streamlining operations and maximizing profits
      </p>
      <div className="Banner__buttons">
        <button 
          className='Banner__button' 
          onClick={handleStartNow}
        >
          Start Now - its Free
        </button>
        <button 
          className='Banner__button Banner__button__gray' 
          onClick={handleMeetExpert}
        >
          Meet an Expert
        </button>
      </div>
      {isMeetExpertFormVisible && (
        <div className="modal">
          <MeetExpertForm setIsMeetExpertFormVisible={setIsMeetExpertFormVisible} />
        </div>
      )}
    </div>
  )
};

export default Banner;