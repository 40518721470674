import { HomePage } from './HomePage/HomePage'
import { Route, Routes } from 'react-router-dom';
import { SubscriptionsPage } from './Subscribtion/SubscriptionPage'
import { PrivateRoute } from '../components/PrivateRoute/PrivateRoute'
import { WareHousesPage } from '../pages/WareHouses/WareHousesPage'
import { LocationPage } from '../pages/Location/LocationPage'
import { WareHousesDetailPage } from '../pages/WareHousesDetailPage/WareHousesDetailPage'
import { ProductsPage } from './Products/ProductsPage'
import { ProfilePage } from './Profile/ProfilePage'
import { ProductDetailPage } from './ProductDetailPage/ProductDetailPage'
import { CocktailsDetailPage } from './CocktailsDetailPage/CocktailsDetailPage';
import { BasketPage } from './Basket/BasketPage'
import { OrdersPage } from './Orders/OrdersPage';
import { LeadPage } from './Leads/Leads'
import { NotFound } from '../components/NotFound/NotFound';
import ComingSoon from './ComingSoon/ComingSoon'; 
import { comingSoonPaths, devFeaturesEnabled } from '../config/featureFlags'; 

export const Pages = ({ setIsRegisterFormVisible }) => {

  return (
    <Routes>
      <Route path="/" element={<HomePage setIsRegisterFormVisible={setIsRegisterFormVisible} />} />
      <Route
          path="/subscriptions"
        element={<PrivateRoute element={<SubscriptionsPage isButton={true} />} isAuthenticated={localStorage.getItem('user')} />}
      />
       <Route
          path="/ware-houses"
        element={<PrivateRoute element={<WareHousesPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
          path="/locations"
        element={<PrivateRoute element={<LocationPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path="/ware-house/:number"
        element={<PrivateRoute element={<WareHousesDetailPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path="/products" element={<ProductsPage />} 
      />
      <Route
        path="/profile"
        element={<PrivateRoute element={<ProfilePage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path='/product/:number'
        element={<PrivateRoute element={<ProductDetailPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path='/cocktails/:number'
        element={<PrivateRoute element={<CocktailsDetailPage  />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path='/basket/'
        element={<PrivateRoute element={<BasketPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route
        path='/orders/'
        element={<PrivateRoute element={<OrdersPage />} isAuthenticated={localStorage.getItem('user')} />}
      />
      <Route 
        path="/lead-list" 
        element={
          comingSoonPaths.includes('/lead-list') && !devFeaturesEnabled 
            ? <ComingSoon /> 
            : <LeadPage />
        }
      />
      {/* Catch-all route for 404 Not Found */}
      <Route path="*" element={<NotFound />} /> 
  </Routes>
  )
}
export default Pages;