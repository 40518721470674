import axios from 'axios';
import { authService } from './AuthService';

export const requestInstance = axios.create();

requestInstance.interceptors.request.use(
  async config => {
    try {
      const access_token = localStorage.getItem('access_token');
      console.log('Current access token:', access_token ? 'Present' : 'Missing');
      
      if (access_token) {
        config.headers['Authorization'] = 'Bearer ' + access_token;
      } else {
        console.warn('No access token found in localStorage');
      }
      
      console.log('Making request to:', config.url);
      return config;
    } catch (error) {
      console.error('Error in request interceptor:', error);
      return Promise.reject(error);
    }
  },
  error => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

requestInstance.interceptors.response.use(
  response => {
    console.log('Response received:', {
      url: response.config.url,
      status: response.status,
      hasData: !!response.data
    });
    return response;
  },
  async error => {
    const originalRequest = error.config;
    console.error('Response error:', {
      url: originalRequest?.url,
      status: error.response?.status,
      message: error.message
    });

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        console.log('Attempting to refresh token...');
        const newAccessToken = await authService.refreshToken();
        if (newAccessToken) {
          console.log('Token refreshed successfully');
          localStorage.setItem('access_token', newAccessToken);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + newAccessToken;
          return requestInstance(originalRequest);
        } else {
          console.error('Failed to get new access token');
          throw new Error('Token refresh failed');
        }
      } catch (refreshError) {
        console.error('Token refresh error:', refreshError);
        if (refreshError.response?.status === 400) {
          console.log('Clearing auth data due to refresh token failure');
          localStorage.removeItem('user');
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          window.location.reload();
        }
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default requestInstance;
