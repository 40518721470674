import { BASE_URL } from '../../api/constants';
import { requestInstance } from '../../services/AuthService/AccessTokenUpdateSevice';
import { useState } from 'react';
import './ExpertForm.scss';

export const MeetExpertForm = ({ setIsMeetExpertFormVisible }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [brandName, setBrandName] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrors({}); // Reset errors

    const formData = { name, email, phone, brandName };

    try {
      const response = await requestInstance.post(`${BASE_URL}support/expert/`, formData);
      
      if (response.status === 200) {
        setIsSubmitted(true);  
      }
    } catch (error) {
      setIsSubmitting(false);
      if (error.response && error.response.status === 400) {
        const errorData = error.response.data;
        setErrors(errorData.errors || {});
      } else {
        setErrors({ general: "Something went wrong. Please try again later." });
      }
    }
  };

  // Check if the input field is empty and add/remove error style
  const handleInputChange = (field, setter, event) => {
    const value = event.target.value;
    setter(value);
  
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: `${field.charAt(0).toUpperCase() + field.slice(1)} is required`,
      }));
    } else {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[field];
        return updatedErrors;
      });
    }
  };

  if (isSubmitted) {
    return (
      <div className="ExpertForm">
        <h2 className="ExpertForm__title">Thank you!</h2>
        <p>Your information has been submitted.</p>
        <button className="ExpertForm__submit" onClick={() => setIsMeetExpertFormVisible(false)}>Close</button>
      </div>
    );
  }

  return (
    <div className="ExpertForm">
      <h2 className="ExpertForm__title">
        Meet an Expert
        <span className='closeFormExpert' onClick={() => setIsMeetExpertFormVisible(false)}>x</span>
      </h2>
      <form className="ExpertForm__form" onSubmit={handleSubmit}>
        <div className={`ExpertForm__input-group ${errors.name ? 'error' : ''}`}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => handleInputChange('name', setName, e)}
          />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>

        <div className={`ExpertForm__input-group ${errors.email ? 'error' : ''}`}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => handleInputChange('email', setEmail, e)}
          />
          {errors.email && <span className="error-message">{errors.email}</span>}
        </div>

        <div className="ExpertForm__input-group">
          <label htmlFor="phone">Phone (optional)</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>

        <div className={`ExpertForm__input-group ${errors.brandName ? 'error' : ''}`}>
          <label htmlFor="brandName">Brand Name</label>
          <input
            type="text"
            id="brandName"
            name="brandName"
            value={brandName}
            onChange={(e) => handleInputChange('brandName', setBrandName, e)}
          />
          {errors.brandName && <span className="error-message">{errors.brandName}</span>}
        </div>
        <button style={{ marginBottom: "30px" }} type="submit" className="ExpertForm__submit" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
        {errors.general && <div className="error-message">{errors.general}</div>}
      </form>
    </div>
  );
};

export default MeetExpertForm;