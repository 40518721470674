import './App.css';
import { UserProvider } from './context/UserContext.js';
import { CartProvider } from './context/CartContext.js';
import { SearchProvider } from './context/SearchContext.js';
import { BrowserRouter as Router } from 'react-router-dom';
import { Main } from './components/Main.jsx'

const App = () => {
  return (
    <UserProvider>
      <CartProvider>
        <SearchProvider>
          <Router>
            <Main />
          </Router>
        </SearchProvider>
      </CartProvider>
    </UserProvider>
  );
}

export default App;
