import React from 'react';
import './ComingSoon.scss'; // We'll create this next

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <h1>Coming Soon!</h1>
      <p>This feature is currently under development.</p>
      <p>We're working hard to bring it to you.</p>
      {/* Optional: Add a link back to the homepage */}
      {/* <a href="/">Go back home</a> */}
    </div>
  );
};

export default ComingSoon;
