import {Subscription} from './Subscribtion/Subscribtion'


export const SubscriptionsPage = () => {
  return (
    <>
      <Subscription isButton={true} selected="seller" />
    </>
  )
}

export default SubscriptionsPage;