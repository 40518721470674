/**
 * @fileoverview HomePage Component - Main landing page of the application
 * Assembles and renders all major sections of the homepage in a specific order
 */

import { useState } from 'react';
import { Banner } from './Banner/Banner';
import { Benefits } from './Benefits/Benefits';
import { Features } from './Features/Features';
import { Subscription } from '../Subscribtion/Subscribtion/Subscribtion';
import { Poster } from './Poster/Poster';
import { Reseller } from './Reseller/Reseller';

/**
 * HomePage Component
 * @component
 * @description Renders the main landing page with all its sections including
 * banner, benefits, features, subscription options, poster, and reseller information
 * @returns {JSX.Element} The complete homepage layout
 */
export const HomePage = () => {
  const [selected, setSelected] = useState('seller');
  const [isRegisterFormVisible, setIsRegisterFormVisible] = useState(false);

  return (
    <>
      <Banner selected={selected} setIsRegisterFormVisible={setIsRegisterFormVisible} />
      <Benefits selected={selected} setSelected={setSelected} />
      <Features selected={selected} />
      <Subscription isButton={false} selected={selected} />
      <Poster selected={selected} />
      <Reseller selected={selected} />
    </>  
  )
}

export default HomePage;