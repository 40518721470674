import './Footer.scss';
import facebook from './images/facebook.png';
import twitter from './images/twitter.png';
import instagram from './images/instagram.png'
import {Link} from 'react-router-dom';
import logo from '../Header/images/logo1.png';


export const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__content">
        <div className="Footer__section Footer__section--brand">
          <div className="Footer__logo-container">
            <img src={logo} alt='Logo' className="Footer__logo-img" />
          </div>
          <p className="Footer__mission">
            Our Mission: To simplify the overwhelming complexity of the global alcohol industry by directly connecting craft and independent brands with resellers—empowering seamless interactions through smart, efficient processes
          </p>
        </div>
        <div className="Footer__section">
          <h3>About</h3>
          <ul>
            <li><Link to=''>Home</Link></li>
            <li><Link to='subscriptions/'>Subscription</Link></li>
            <li>Benefits</li>
            <li>Team</li>
            <li>Careers</li>
          </ul>
        </div>
        <div className="Footer__section">
          <h3>Follow Us</h3>
          <ul>
            <li><img className="SocialIcons" src={facebook} alt='Facebook' /> Facebook</li>
            <li><img className="SocialIcons" src={twitter} alt='Twitter' /> Twitter</li>
            <li><img className="SocialIcons" src={instagram} alt='Instagram' /> Instagram</li>
          </ul>
        </div>
      </div>
      <div className="Footer__bottom">
        <p>© 2024 Tyrrst. All rights reserved.</p>
        <div>
          <a href="#terms">Terms & Conditions</a>
          <a href="#privacy">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer