import { useLocation } from 'react-router-dom';
import { Header } from './Header/Header.jsx'
import { Pages } from '../pages/Pages.jsx'
import { Footer } from './Footer/Footer.jsx';
import { ScrollToTop } from './ScrollTop/ScrollTop.jsx';
import {useState} from 'react'
import '../App.css'

export const Main = () => {
  const location = useLocation();
  const isBlack = location.pathname === '/';
  const isHomePage = location.pathname === '/'; // Explicit check for homepage
  const [isRegisterFormVisible, setIsRegisterFormVisible] = useState(false);

  return (
    <>
      <ScrollToTop />
      <Header 
        isBlack={isBlack} 
        isRegisterFormVisible={isRegisterFormVisible}
        setIsRegisterFormVisible={setIsRegisterFormVisible}
      />
      {/* Add conditional 'homepage' class based on route */}
      <div className={`App ${isHomePage ? 'homepage' : ''}`}>
        <Pages setIsRegisterFormVisible={setIsRegisterFormVisible} />
      </div>
      <Footer />
    </>
  );
}
export default Main;