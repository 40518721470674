import './EmptyPage.scss';
import minimalEmptyCartSvg from './images/minimalEmptyCart.svg';

export const EmptyPage = ({text}) => {
  return (
    <div className='EmptyPage'>
      <img className='EmptyPage__img' src={ minimalEmptyCartSvg } alt='empty cart'/>
      <div className='EmptyPage__text'>{ text }</div>
    </div>
  )
}

export default EmptyPage;