import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import './SearchResults.scss';

// ProductCard for displaying product search results
const ProductCard = ({ product, onClick }) => {
  // Enhanced debugging to log what product data is available
  console.log('Rendering ProductCard with data:', product);
  
  // Extract main image URL from product images if available
  const mainImage = product.images && product.images.length > 0 
    ? product.images.find(img => img.main)?.image 
    : null;
  
  return (
    <div className="product-card" onClick={(e) => {
      e.stopPropagation(); // Prevent event bubbling
      onClick(product);
    }}>
      <div className="product-card__image-container">
        {mainImage ? (
          <img 
            src={mainImage} 
            alt={product.name} 
            className="product-card__image"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/placeholder-product.jpg'; // Fallback image
            }}
          />
        ) : (
          <div className="product-card__placeholder">No Image</div>
        )}
      </div>
      <div className="product-card__content">
        <h3 className="product-card__title">{product.name || 'Unnamed Product'}</h3>
        <p className="product-card__category">{product.category?.name || 'Uncategorized'}</p>
        <p className="product-card__company">{product.company?.brand_name || 'Unknown Brand'}</p>
        <div className="product-card__details">
          <div className="product-card__detail">
            <span className="product-card__label">Price:</span>
            <span className="product-card__value">{product.price} {product.currency}</span>
          </div>
          <div className="product-card__detail">
            <span className="product-card__label">ABV:</span>
            <span className="product-card__value">{product.alcohol_percentage}%</span>
          </div>
          <div className="product-card__detail">
            <span className="product-card__label">Size:</span>
            <span className="product-card__value">{product.bottle_size}</span>
          </div>
        </div>
        {product.description && (
          <p className="product-card__description">
            {product.description.length > 100
              ? `${product.description.substring(0, 100)}...`
              : product.description}
          </p>
        )}
      </div>
      <div className="product-card__cta">
        <button 
          className="product-card__button"
          onClick={(e) => {
            e.stopPropagation(); // Prevent double click event
            onClick(product);
          }}
        >
          View Details
        </button>
      </div>
    </div>
  );
};

// BuyerCard for displaying buyer search results
const BuyerCard = ({ buyer, onClick }) => {
  // Enhanced debugging to log what buyer data is available
  console.log('Rendering BuyerCard with data:', buyer);
  
  return (
    <div className="buyer-card" onClick={(e) => {
      e.stopPropagation(); // Prevent event bubbling
      onClick(buyer);
    }}>
      <div className="buyer-card__header">
        <h3 className="buyer-card__title">{buyer.brand_name || 'Unnamed Company'}</h3>
        <span className="buyer-card__location">{buyer.country}</span>
      </div>
      <div className="buyer-card__content">
        <div className="buyer-card__contact">
          <p className="buyer-card__contact-name">{buyer.first_name} {buyer.last_name}</p>
          <p className="buyer-card__contact-info">{buyer.email}</p>
          {buyer.phone && <p className="buyer-card__contact-info">{buyer.phone}</p>}
        </div>
        <div className="buyer-card__details">
          <div className="buyer-card__detail">
            <span className="buyer-card__label">Size:</span>
            <span className="buyer-card__value">{buyer.company_size || 'Unknown'}</span>
          </div>
          <div className="buyer-card__detail">
            <span className="buyer-card__label">Language:</span>
            <span className="buyer-card__value">{buyer.language || 'Not specified'}</span>
          </div>
          <div className="buyer-card__detail">
            <span className="buyer-card__label">Interests:</span>
            <span className="buyer-card__value">{buyer.primary_interests || 'Not specified'}</span>
          </div>
        </div>
      </div>
      <div className="buyer-card__cta">
        <button 
          className="buyer-card__button"
          onClick={(e) => {
            e.stopPropagation(); // Prevent double click event
            onClick(buyer);
          }}
        >
          Contact Buyer
        </button>
      </div>
    </div>
  );
};

// AuthPrompt for unregistered users
const AuthPrompt = () => {
  const navigate = useNavigate();
  
  return (
    <div className="auth-prompt">
      <div className="auth-prompt__content">
        <h3>Unlock Full Access</h3>
        <p>Create an account to view complete product details and make purchases.</p>
        <div className="auth-prompt__buttons">
          <button 
            className="auth-prompt__button auth-prompt__button--login"
            onClick={() => navigate('/auth/login')}
          >
            Log In
          </button>
          <button 
            className="auth-prompt__button auth-prompt__button--register"
            onClick={() => navigate('/auth/register')}
          >
            Register Now
          </button>
        </div>
      </div>
    </div>
  );
};

// Empty state component
const EmptyState = ({ query }) => (
  <div className="empty-state">
    <div className="empty-state__icon">🔍</div>
    <h3 className="empty-state__title">No results found</h3>
    <p className="empty-state__message">
      {query 
        ? `We couldn't find any results for "${query}". Try adjusting your search.` 
        : 'Try searching for products using the search bar above.'}
    </p>
  </div>
);

// Loading state component
const LoadingState = () => (
  <div className="loading-state">
    <div className="loading-state__spinner"></div>
    <p className="loading-state__message">Finding the best matches for you...</p>
  </div>
);

// Error state component
const ErrorState = ({ error }) => (
  <div className="error-state">
    <div className="error-state__icon">⚠️</div>
    <h3 className="error-state__title">Something went wrong</h3>
    <p className="error-state__message">
      {error?.message || 'An error occurred while fetching results. Please try again.'}
    </p>
  </div>
);

export const SearchResults = ({ 
  results = [], 
  isLoading = false, 
  error = null, 
  searchType = null,
  query = '',
  count = 0,
  onProductClick = () => {},
  onBuyerClick = () => {}
}) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [showDebugInfo, setShowDebugInfo] = useState(false);
  
  // Debug log to see exactly what props we're receiving
  console.log('SearchResults component props:', {
    resultsLength: results?.length || 0,
    firstResult: results?.[0],
    isLoading,
    error,
    searchType,
    query,
    count,
    isAuthenticated: !!user
  });
  
  // Determine the type of results to display based on searchType prop or logged-in user type
  const effectiveSearchType = searchType || (user?.company?.type_of_business === 'brand' ? 'buyer' : 'product');
  const isUnregistered = !user;
  
  // Handle product card click
  const handleProductClick = (product) => {
    console.log('Product clicked:', product);
    if (isUnregistered) {
      // Store the product ID in localStorage to redirect after auth
      localStorage.setItem('redirectProductId', product.id);
    } else {
      // Navigate to product detail page
      navigate(`/products/${product.id}`);
    }
    // Call the parent click handler
    onProductClick(product);
  };
  
  // Handle buyer card click
  const handleBuyerClick = (buyer) => {
    console.log('Buyer clicked:', buyer);
    // Call the parent click handler
    onBuyerClick(buyer);
  };

  // Toggle debug info display
  const toggleDebugInfo = (e) => {
    e.stopPropagation();
    setShowDebugInfo(!showDebugInfo);
  };

  // Render appropriate state
  if (isLoading) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState error={error} />;
  }

  if (!results || results.length === 0) {
    return <EmptyState query={query} />;
  }

  return (
    <div className="search-results">
      {/* Debug button */}
      <button 
        onClick={toggleDebugInfo} 
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 1000,
          fontSize: '10px',
          background: '#f0f0f0',
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '2px 5px'
        }}
      >
        {showDebugInfo ? 'Hide Debug' : 'Show Debug'}
      </button>
      
      {/* Debug info */}
      {showDebugInfo && (
        <div style={{
          position: 'absolute',
          top: '35px',
          right: '10px',
          zIndex: 1000,
          background: 'rgba(240, 240, 240, 0.9)',
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '10px',
          fontSize: '12px',
          maxWidth: '300px',
          maxHeight: '200px',
          overflow: 'auto'
        }}>
          <p><strong>Results:</strong> {results.length}</p>
          <p><strong>Search Type:</strong> {effectiveSearchType}</p>
          <p><strong>Query:</strong> {query || 'Empty'}</p>
          <p><strong>User Type:</strong> {user ? user.company?.type_of_business || 'No company' : 'Unauthenticated'}</p>
          <p><strong>First Result:</strong></p>
          <pre style={{fontSize: '10px', overflow: 'auto', maxHeight: '80px'}}>
            {JSON.stringify(results[0], null, 2)}
          </pre>
        </div>
      )}
      
      <div className="search-results__header">
        <h2 className="search-results__title">
          {effectiveSearchType === 'buyer' ? 'Potential Buyers' : 'Products'}
        </h2>
        <p className="search-results__count">Found {count} results</p>
      </div>
      
      <div className="search-results__grid">
        {effectiveSearchType === 'buyer' 
          ? results.map((buyer, index) => (
              <BuyerCard 
                key={buyer.id || index} 
                buyer={buyer} 
                onClick={handleBuyerClick}
              />
            ))
          : results.map((product, index) => (
              <ProductCard 
                key={product.id || index} 
                product={product} 
                onClick={handleProductClick}
              />
            ))
        }
      </div>
      
      {isUnregistered && effectiveSearchType === 'product' && results.length > 0 && (
        <AuthPrompt />
      )}
    </div>
  );
};

export default SearchResults;
