import './Subscription.scss';
import { SubscriptionType } from '../SubscriptionType/SubscriptionType'
import {getSubscription} from '../../../api/subscribtion/getSubscriptions'
import { useState, useEffect } from 'react';
import {requestInstance} from '../../../services/AuthService/AccessTokenUpdateSevice'
import { BASE_URL } from '../../../api/constants';
import { PaymentModal } from '../../../components/PaymentModal/PaymentModal'
import { MeetExpertForm } from '../../../components/ExpertForm/ExpertForm';

const SUBSCRIPTION_TEXT = {
  seller: "You can choose from three membership plans: Freeby, Premium and Enterprise. Freeby gives you access to the Tyrrst marketplace and puts you in contact with resellers across >10 European countries - but you need to take care of the logistics yourself; Premium and Enterprise levels allow you to use Tyrrst Logistics including free storage and low cost, small batch excise and delivery services as well as be part of Tyrrst Marketing and promotion events. We also offer a standard payment option where you receive your payment in 30 days or an accelerated payment alternative where you can receive payment in just 5 days.",
  buyer: "Join us today - it's free! Just create a profile on Tyrrst and start exploring our many great brands and products. Order without hassle and pay later! We will soon also offer a premium membership that will allow for even more savings - and for turning your profile into a customer facing website that you can use for your own marketing!"
};

const content = {
  seller: {
    title: "Freeby",
    price: "€0.00",
    period: "/month",
    features: [
      {
        title: "Marketplace sales fee",
        items: [
          { text: "Your existing customers", value: "0%" }, 
          { text: "Customers through Tyrrst", value: "20%" }
        ]
      },
      {
        title: "Payment",
        items: [
          { text: "Accelerated payment (5 days)", value: "3%" }, 
          { text: "30 day payment", value: "0%" }
        ]
      }
    ],
    buttonText: "Sign me up"
  },
  buyer: {
    title: "Marketplace",
    price: "€0.00",
    period: "/month",
    features: [
      {
        title: "Marketplace",
        items: [
          { text: "Search products across many brands" }, 
          { text: "Buy multiple brands on a single invoice" }, 
          { text: "Free shipment over Euro 300" }, 
          { text: "Directly message brands" }, 
          { text: "Tender for special needs" }
        ]
      },
      {
        title: "Collect Tyrrst Membership points",
        items: [
          { text: "Save 5%, 8%, 10% or more" }
        ]
      },
      {
        title: "Payment",
        items: [
          { text: "Financing up to 90 days" }
        ]
      }
    ],
    status: "Selected",
    buttonText: "Sign me up"
  }
};

export const Subscription = ({ selected, isButton = true }) => {
  const [listSubscriptions, setListSubscriptions] = useState([]);
  const [currentSub, setCurrentSub] = useState(null);
  const [userId, setUserId] = useState(null);
  const [visibilityPaymentModal, setVisibilityPaymentModal] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [showRegistration, setShowRegistration] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setUserId(JSON.parse(user).id);
    }
  }, []);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const subscriptions = await getSubscription();
        // Filter subscriptions based on selected type
        const filteredSubscriptions = subscriptions.results.filter(sub => 
          selected === 'seller' ? sub.name !== 'Freeby' : sub.name === 'Freeby'
        );
        setListSubscriptions(filteredSubscriptions || []);
      } catch (error) {
        console.error('Failed to fetch subscriptions:', error);
      }
    };

    fetchSubscriptions();
  }, [selected]);

  useEffect(() => {
    const getMyCurrentSub = async () => {
      try {
        const url = BASE_URL + 'subscriptions/my-current-sub/';
        const response = await requestInstance.get(url);
        setCurrentSub(response.data.subscription.id);
      } catch (error) {
        console.error('Failed to fetch current subscription:', error);
      }
    };

    if (isButton) {
      getMyCurrentSub();
    }
  }, [isButton]);

  const handleSubscriptionChange = (newSubId) => {
    setCurrentSub(newSubId);
  };

  const handleSignUp = () => {
    setShowRegistration(true);
  };

  

  const currentPlan = content[selected];

  return (
    <div className="Subscription">
      {userId && visibilityPaymentModal && (
        <div className="ModalOverlay">
          <PaymentModal
            onClose={() => setVisibilityPaymentModal(false)}
            subscriptionId={subscriptionId}
            userId={userId}
            setCurrentSub={(item) => {setCurrentSub(item)}}
          />
        </div>
      )}
      <h1 className="Subscription__title">Subscription</h1>
      <p className="Subscription__text">
        {SUBSCRIPTION_TEXT[selected]}
      </p>
     
      <div className="Subscription__card">
        <div className="Subscription__title-pill">
          <h2>{currentPlan.title}</h2>
        </div>
        
        <div className="Subscription__price">
          <span className="Subscription__amount">{currentPlan.price}</span>
          <span className="Subscription__period">{currentPlan.period}</span>
        </div>
        
        {currentPlan.features.map((feature, index) => (
          <div key={index} className="Subscription__feature-group">
            <h3 className="Subscription__feature-title">{feature.title}</h3>
            <ul className="Subscription__feature-list">
              {feature.items.map((item, itemIndex) => (
                <li key={itemIndex} className="Subscription__feature-item">
                  <span className="Subscription__feature-text">{item.text}</span>
                  {item.value && <span className="Subscription__feature-value">{item.value}</span>}
                </li>
              ))}
            </ul>
          </div>
        ))}

        {currentPlan.status && (
          <div className="Subscription__status">{currentPlan.status}</div>
        )}

        <button className="Subscription__button" onClick={handleSignUp}>
          {currentPlan.buttonText || "Sign me up"}
        </button>
      </div>

      {showRegistration && (
        <div className="Subscription__modal">
          <div className="Subscription__modal-content">
            <MeetExpertForm setIsMeetExpertFormVisible={setShowRegistration} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscription;