// src/config/featureFlags.js

// List of paths for features that are currently under development
// and should show the 'Coming Soon' page by default.
export const comingSoonPaths = [
  '/lead-list',
  // Add other paths here, e.g.:
  // '/new-feature',
  // '/admin-dashboard'
];

// Check the environment variable to see if development features should be enabled.
// Set REACT_APP_ENABLE_DEV_FEATURES=true in your local .env file to bypass 'Coming Soon'.
export const devFeaturesEnabled = process.env.REACT_APP_ENABLE_DEV_FEATURES === 'true';
