import logo from './images/logo1.png';
import burgerElement from './images/burgerElement.png';
import emptyUser from './images/emptyUser.png';
import plane from './images/plane.png';
import bell from './images/bell.png';
import './Header.scss';
import cn from 'classnames'
import { LoginForm } from '../Login/LoginForm';
import { ForgotPasswordForm1 } from '../ForgotPaswordForms/ForgotPasswordForm1';
import { ForgotPasswordForm2 } from '../ForgotPaswordForms/ForgotPasswordForm2';
import { ForgotPasswordForm3 } from '../ForgotPaswordForms/ForgotPasswordForm3';
import { CompanyForm } from '../Registration/CompanyForm/CompanyForm'
import { UserForm } from '../Registration/UserForm/UserForm'
import { EmailConfirmationForm } from '../Registration/EmailConfirmationForm/EmailConfirmationForm'
import { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useUser } from '../../context/UserContext';
import { useCart } from '../../context/CartContext';
import {Link, useLocation, useNavigate} from 'react-router-dom'
import { SearchBar } from '../Search/SearchBar';
import { SearchResults } from '../Search/SearchResults';

// Define modal types
const MODAL_TYPES = {
  LOGIN: 'login',
  REGISTER_COMPANY: 'registerCompany',
  REGISTER_USER: 'registerUser',
  EMAIL_CONFIRMATION: 'emailConfirmation',
  FORGOT_PASSWORD_1: 'forgotPassword1',
  FORGOT_PASSWORD_2: 'forgotPassword2',
  FORGOT_PASSWORD_3: 'forgotPassword3',
};

export const Header = ({ 
  isBlack = true, 
  isRegisterFormVisible, 
  setIsRegisterFormVisible // Keep this prop to allow external triggering
}) => {
  const [isLoginFormVisible, setIsLoginFormVisible] = useState(false);
  const [isUserFormVisible, setIsUserFormVisible] = useState(false);
  const [isEmailConfirmationFormVisible, setIsEmailConfirmationFormVisible] = useState(false);
  const [isForgotPasswordForm1Visible, setIsForgotPasswordForm1Visible] = useState(false);
  const [isForgotPasswordForm2Visible, setIsForgotPasswordForm2Visible] = useState(false);
  const [isForgotPasswordForm3Visible, setIsForgotPasswordForm3Visible] = useState(false);
  const [activeModal, setActiveModal] = useState(null); 
  const { user } = useUser();
  const { getCartTotal } = useCart();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const [searchTypeResult, setSearchTypeResult] = useState('product');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCount, setSearchCount] = useState(0);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const searchResultsRef = useRef(null);

  // For debugging - log when search results state changes
  useEffect(() => {
    console.log('Search results state changed:', {
      resultsCount: searchResults?.length || 0,
      showSearchResults,
      searchError
    });
  }, [searchResults, showSearchResults, searchError]);

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    window.location.href = '/';
  };

  const handleSearchResults = ({ results, loading, error, type, count, query }) => {
    console.log('handleSearchResults called with:', { 
      resultsCount: results?.length || 0, 
      loading, 
      hasError: !!error,
      query
    });
    
    setSearchResults(results || []);
    setSearchLoading(loading || false);
    setSearchError(error || null);
    setSearchTypeResult(type || 'product'); 
    setSearchCount(count || 0);
    if (query) setSearchQuery(query);
    
    // Show results if search is performed (even if empty results)
    // We'll handle the "no results" state in the SearchResults component
    if (!loading) {
      setShowSearchResults(true);
      console.log('Setting showSearchResults to TRUE');
    }
  };

  const handleProductClick = (product) => {
    // For authenticated users, navigate is handled in SearchResults component
    // For unauthenticated users, toggleLoginForm and store product ID
    if (!user) {
      localStorage.setItem('redirectProductId', product.id);
      toggleLoginForm();
    }
    // Hide search results when a product is clicked
    setShowSearchResults(false);
  };

  const handleBuyerClick = (buyer) => {
    // Handle buyer click based on your app's requirements
    // e.g., show contact modal or navigate to buyer details
    console.log('Buyer clicked:', buyer);
    // Hide search results when a buyer is clicked
    setShowSearchResults(false);
  };

  const handleCloseSearchResults = () => {
    setShowSearchResults(false);
    // Optionally clear results when closing
    // setSearchResults([]);
  };

  const handleViewAllResults = () => {
    const userType = user?.company?.type_of_business || 'unregistered';
    // Get the current search query from the search results
    const currentQuery = document.querySelector('.Header__search-bar input')?.value || '';
    
    // Navigate to the appropriate page based on search type
    if (userType === 'brand') {
      // Navigate back to the lead list page for brands (to see buyers)
      navigate('/lead-list', { 
        state: { searchQuery: currentQuery, searchType: 'buyer' } 
      });
    } else {
      // Navigate to products page for everyone else
      navigate('/products', { 
        state: { searchQuery: currentQuery, searchType: 'product' } 
      });
    }
    // Close the search results dropdown
    handleCloseSearchResults();
  };

  const openModal = (modalType) => {
    setActiveModal(modalType);
    // If the register form was triggered externally, opening any modal should reset that trigger
    if (isRegisterFormVisible && modalType !== MODAL_TYPES.REGISTER_COMPANY) {
      setIsRegisterFormVisible(false);
    }
  };

  const closeModal = () => {
    setActiveModal(null);
    // Also reset the external trigger if it was active
    if (isRegisterFormVisible) {
      setIsRegisterFormVisible(false);
    }
  };

  useEffect(() => {
    // Handle external trigger for registration form
    if (isRegisterFormVisible && activeModal !== MODAL_TYPES.REGISTER_COMPANY) {
      openModal(MODAL_TYPES.REGISTER_COMPANY);
    }
  }, [isRegisterFormVisible]); // Only react to external trigger changes

  useEffect(() => {
    // Update body overflow based on *any* active modal
    if (activeModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    // Cleanup function remains the same
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [activeModal]); // Depend on the single activeModal state

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close search results when clicking outside
      if (searchResultsRef.current && !searchResultsRef.current.contains(event.target) && 
          !event.target.closest('.search-bar')) {
        setShowSearchResults(false);
      }
      
      // Close menu when clicking outside
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, searchResultsRef]);

  const toggleLoginForm = () => {
    setIsLoginFormVisible(!isLoginFormVisible);
  };

  const toggleCompanyForm = () => {
    setIsRegisterFormVisible(!isRegisterFormVisible);
  };

  // Force search results visible for debugging
  const forceShowResults = () => {
    setShowSearchResults(true);
    console.log('Forced search results visibility:', showSearchResults);
  };

  return (
    <>
    <header className={cn({ 'Header': true, 'Header__white': !isBlack })}>
      <div className='Header__logo-container'>
        <Link to='/'><img src={logo} alt='Logo' className={cn({ 'Header__logo': true, 'Header__logo__white': !isBlack })} /></Link>
      </div>

      <nav className={cn({ 'Header__nav': true, 'Header__nav__white': !isBlack })}>          
        <SearchBar onResultsUpdate={handleSearchResults} />
        
        {/* Search Results Dropdown - Always render with conditional CSS for visibility */}
        <div 
          className="Header__search-results" 
          ref={searchResultsRef}
          style={{ display: showSearchResults ? 'block' : 'none' }}
        >
          <div className="Header__search-results-actions">
            <button 
              className="Header__search-results-close" 
              onClick={handleCloseSearchResults}
            >
              <span aria-hidden="true">×</span>
            </button>
            <button 
              className="Header__search-results-view-all" 
              onClick={handleViewAllResults}
            >
              View All Results
            </button>
          </div>
          <SearchResults 
            results={searchResults}
            isLoading={searchLoading}
            error={searchError}
            searchType={searchTypeResult}
            query={searchQuery}
            count={searchCount}
            onProductClick={handleProductClick}
            onBuyerClick={handleBuyerClick}
          />
        </div>
      </nav>

      <div className='Header__block'>
        <div className='Header__actions'>
          {user && (
            <div className="Header__cart">
              <Link to="/cart" className={cn({ 'Header__cart-link': true, 'Header__cart-link__white': !isBlack })}>
                <svg className={cn({ 'Header__cart-icon': true, 'Header__cart-icon__white': !isBlack })} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
                {getCartTotal() > 0 && (
                  <span className="Header__cart-count">{getCartTotal()}</span>
                )}
              </Link>
            </div>
          )}
          {!user ? (
            <div className={cn({ 'Header__auth-buttons': true, 'Header__auth-buttons__white': !isBlack })}>
              <button 
                className={cn({ 'Header__auth-button': true, 'Header__auth-button__white': !isBlack })}
                onClick={() => openModal(MODAL_TYPES.LOGIN)}
              >
                Login
              </button>
              <button 
                className={cn({ 'Header__auth-button': true, 'Header__auth-button__white': !isBlack })}
                onClick={() => openModal(MODAL_TYPES.REGISTER_COMPANY)}
              >
                Register
              </button>
            </div>
          ) : (
            <>
              <img src={bell} alt='notifications' className={cn({ 'Header__icon': true, 'Header__icon__white': !isBlack })}/>
              <img src={plane} alt='messages' className={cn({ 'Header__icon': true, 'Header__icon__white': !isBlack })}/>
            </>
          )}
        </div>
        
        {user && (
          <div className={cn({ 'Header__user': true, 'Header__user__white': !isBlack })}>
            <div className='Header__user__menu' onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <img src={burgerElement} alt='burger' className={cn({ 'Header__menu-line': true, 'Header__menu-line__white': !isBlack })} />
              <img src={burgerElement} alt='burger' className={cn({ 'Header__menu-line': true, 'Header__menu-line__white': !isBlack })} />
              <img src={burgerElement} alt='burger' className={cn({ 'Header__menu-line': true, 'Header__menu-line__white': !isBlack })} />
            </div>
            <Link to='profile/'>
              <img
                className='Header__user__avatar'
                src={user?.user_photo || emptyUser}
                alt='User avatar'
              />
            </Link>
          </div>
        )}
          
        {isMenuOpen && user && (
          <div className="Header__dropdown" ref={menuRef}>
            <ul>
              <li onClick={() => setIsMenuOpen(false)}><Link to="/orders">My orders</Link></li>
              <li onClick={() => setIsMenuOpen(false)}><Link to="/basket">Basket</Link></li>
              <li onClick={() => setIsMenuOpen(false)}><Link to="/lead-list">Lead List</Link></li>
              <li onClick={logout}><Link to="/">Log out</Link></li> 
            </ul>
          </div>
        )}
      </div>
    </header>

    {(searchLoading || searchError || searchResults.length > 0) && (
      <div className="search-results-area" style={{ padding: '20px', marginTop: '80px'}}> 
        <SearchResults 
          results={searchResults}
          isLoading={searchLoading}
          error={searchError}
          searchType={searchTypeResult} 
        />
      </div>
    )}

    {activeModal === MODAL_TYPES.LOGIN && (
      <div className="Overlay">
        <div className="Overlay__content" onClick={(e) => e.stopPropagation()}>
          <LoginForm
            setIsLoginFormVisible={() => closeModal()} // Close this modal
            setIsRegisterFormVisible={() => openModal(MODAL_TYPES.REGISTER_COMPANY)} // Open company form
            setIsForgotPasswordForm1Visible={() => openModal(MODAL_TYPES.FORGOT_PASSWORD_1)} // Open forgot password 1
          />
        </div>
      </div>
    )}

    {activeModal === MODAL_TYPES.REGISTER_COMPANY && (
      <div className="Overlay">
        <div className="Overlay__content" onClick={(e) => e.stopPropagation()}>
          <CompanyForm
            setIsRegisterFormVisible={() => closeModal()} // Close this modal
            setIsUserFormVisible={() => openModal(MODAL_TYPES.REGISTER_USER)} // Open user form
            setIsLoginFormVisible={() => openModal(MODAL_TYPES.LOGIN)} // Open login form
          />
        </div>
      </div>
    )}

    {activeModal === MODAL_TYPES.REGISTER_USER && (
      <div className="Overlay">
        <div className="Overlay__content" onClick={(e) => e.stopPropagation()}>
          <UserForm
            setIsUserFormVisible={() => closeModal()} // Close this modal
            setIsEmailConfirmationFormVisible={() => openModal(MODAL_TYPES.EMAIL_CONFIRMATION)} // Open email confirmation
          />
        </div>
      </div>
    )}
      
    {activeModal === MODAL_TYPES.EMAIL_CONFIRMATION && (
      <div className="Overlay">
        <div className="Overlay__content" onClick={(e) => e.stopPropagation()}>
          <EmailConfirmationForm
            setIsEmailConfirmationFormVisible={() => closeModal()} // Close this modal
          />
        </div>
      </div>
    )}

    {activeModal === MODAL_TYPES.FORGOT_PASSWORD_1 && (
      <div className="Overlay">
        <div className="Overlay__content" onClick={(e) => e.stopPropagation()}>
          <ForgotPasswordForm1
            setIsForgotPasswordFormVisible={() => closeModal()} // Close this modal
            setIsLoginFormVisible={() => openModal(MODAL_TYPES.LOGIN)} // Open login form
            setIsForgotPasswordForm2Visible={() => openModal(MODAL_TYPES.FORGOT_PASSWORD_2)} // Open forgot password 2
          />
        </div>
      </div>
    )}
    {activeModal === MODAL_TYPES.FORGOT_PASSWORD_2 && (
      <div className="Overlay">
        <div className="Overlay__content" onClick={(e) => e.stopPropagation()}>
          <ForgotPasswordForm2
            setIsForgotPasswordForm2Visible={() => closeModal()} // Close this modal
            setIsForgotPasswordForm3Visible={() => openModal(MODAL_TYPES.FORGOT_PASSWORD_3)} // Open forgot password 3
          />
        </div>
      </div>
    )}
    {activeModal === MODAL_TYPES.FORGOT_PASSWORD_3 && (
      <div className="Overlay">
        <div className="Overlay__content" onClick={(e) => e.stopPropagation()}>
          <ForgotPasswordForm3
            setIsForgotPasswordForm3Visible={() => closeModal()} // Close this modal
          />
        </div>
      </div>
    )}
    </>
  );
};

export default Header;