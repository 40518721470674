import './UserForm.scss';
import { useState } from 'react';
import openEye from './images/open-eye.png';
import closeEye from './images/close-eye.png';
import lock from './images/lock.png';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice'
import { BASE_URL } from '../../../api/constants';


export const UserForm = ({ setIsUserFormVisible, setIsEmailConfirmationFormVisible }) => {
  const [showPassword1, setShowPassword1] = useState(false);
  const [errors, setErrors] = useState({})
  const [userFormData, setUserFormData] = useState({
      username: "",
      company_id: localStorage.getItem('companyID'),
      email: localStorage.getItem('email'), // Add email from localStorage
      password: "",
      confirm_password: ""
  })

  const togglePassword1Visibility = () => {
    setShowPassword1(!showPassword1);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!userFormData.username.trim()) {
      newErrors.username = ['Username is required. Please enter a valid username.'];
    } else if (userFormData.username.length < 3) {
      newErrors.username = ['Username must be at least 3 characters long.'];
    }

    if (!userFormData.password) {
      newErrors.password = ['Password is required. Please enter a password.'];
    } else if (userFormData.password.length < 8) {
      newErrors.password = ['Password must be at least 8 characters long.'];
    }

    if (!userFormData.confirm_password) {
      newErrors.confirm_password = ['Please confirm your password.'];
    } else if (userFormData.password !== userFormData.confirm_password) {
      newErrors.confirm_password = ['Passwords do not match. Please try again.'];
    }

    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserFormData({ ...userFormData, [name]: value });
    // Clear the specific error when user starts typing
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Client-side validation
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const url = BASE_URL + 'auth/registration-2-step/'
      const result = await requestInstance.post(url, userFormData);
      if (result) {
        setIsUserFormVisible(false)
        setIsEmailConfirmationFormVisible(true)
      }
    } catch (error) {
      console.error('Registration failed:', error);
      if (error.response?.data) {
        // Enhanced error handling for server responses
        const serverErrors = error.response.data;
        const enhancedErrors = {};
        
        // Enhance error messages for common issues
        Object.keys(serverErrors).forEach(key => {
          if (key === 'username' && serverErrors[key].includes('already taken')) {
            enhancedErrors[key] = ['This username is already taken. Please choose a different one.'];
          } else if (key === 'password' && serverErrors[key].includes('too common')) {
            enhancedErrors[key] = ['This password is too common. Please choose a stronger password.'];
          } else {
            enhancedErrors[key] = serverErrors[key];
          }
        });
        
        setErrors(enhancedErrors);
      } else {
        setErrors({ general: ['An unexpected error occurred. Please try again later.'] });
      }
    }
  };
  return (
    <div className="LoginForm">
      <h2 className="LoginForm__title">
        Create user
        <span style={{ right: '-120px' }} className='closeForm' onClick={() => setIsUserFormVisible(false)}>x</span>
      </h2>
      <form className="LoginForm__form" method='post' onSubmit={handleSubmit}>
        <div className="LoginForm__input-group">
          <label htmlFor="username">Choose username</label>
          <input
            type="text"
            id="username"
            name="username"
            className={errors.username ? 'error' : ''}
            value={userFormData.username}
            onChange={handleChange}
            placeholder="Enter your username"
          />
          {errors.username && <p className="ErrorText">{errors.username[0]}</p>}
        </div>
        <div className="LoginForm__input-group">
          <label htmlFor="password">Choose password</label>
          <div className="LoginForm__input-password">
            <input
              type={showPassword1 ? "text" : "password"}
              id="password"
              name="password"
              value={userFormData.password}
              onChange={handleChange}
            />
            <button
              type="button"
              className="LoginForm__toggle-password"
              onClick={togglePassword1Visibility}
            >
              <img
                src={showPassword1 ? openEye : closeEye}
                alt={showPassword1 ? "Hide password" : "Show password"}
              />
            </button>
            {errors.password && <p className="ErrorText">{errors.password[0]}</p>}
          </div>
        </div>
        
        <div className="LoginForm__input-group">
          <label htmlFor="password">Confirm password</label>
          <div className="LoginForm__input-password">
            <input
              type={showPassword1 ? "text" : "password"}
              id="confirm_password"
              name="confirm_password"
              value={userFormData.confirm_password}
              onChange={handleChange}
            />
            <button
              type="button"
              className="LoginForm__toggle-password"
              onClick={togglePassword1Visibility}
            >
              <img
                src={showPassword1 ? openEye : closeEye}
                alt={showPassword1 ? "Hide password" : "Show password"}
              />
            </button>
            {errors.confirm_password && <p className="ErrorText">{errors.confirm_password[0]}</p>}
          </div>
        </div>

        <div className="LoginForm__checkbox-group">
          <input type="checkbox" id="rememberMe" name="rememberMe" />
          <label htmlFor="rememberMe">Remember me</label>
        </div>
        {errors.general && <p className="ErrorText" style={{textAlign: 'center', marginBottom: '10px'}}>{errors.general[0]}</p>}
        <button type="submit" className="LoginForm__submit">Create Account</button>
      </form>
      <div className="LoginForm__footer">
        <p className="LoginForm__forgot"><img className="Lock" src={lock} alt='lock'/> I haven't recived any confirmation email</p>
      </div>
    </div>
  );
};

export default UserForm;