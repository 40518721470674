import { BASE_URL } from './constants';
import { requestInstance } from '../services/AuthService/AccessTokenUpdateSevice';

// Service for handling product categories API calls
export const categoryService = {
  // Get all main categories
  getAllCategories: async (params = {}) => {
    try {
      const queryParams = new URLSearchParams();
      
      // Make exist parameter optional
      if (params.exist) {
        queryParams.append('exist', 'true');
      }
      
      if (params.name) {
        queryParams.append('name', params.name);
      }
      
      if (params.withProducts) {
        queryParams.append('with_products', 'true');
      }

      // Add company filter if provided
      if (params.company) {
        queryParams.append('company', params.company);
      }
      
      const url = `${BASE_URL}products/categories/?${queryParams.toString()}`;
      console.log('Fetching categories from:', url);
      
      const response = await requestInstance.get(url);
      console.log('Raw category response:', response);
      
      if (!response || !response.data) {
        throw new Error('Invalid response format from server');
      }
      
      return response.data;
    } catch (error) {
      console.error('Failed to fetch categories:', error);
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        url: error.config?.url
      });
      throw error;
    }
  },
  
  // Get a specific category by ID
  getCategoryById: async (categoryId) => {
    try {
      const url = `${BASE_URL}products/categories/${categoryId}/`;
      const response = await requestInstance.get(url);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch category with ID ${categoryId}:`, error);
      throw error;
    }
  },
  
  // Get subcategories for a specific category
  getSubcategoriesByCategoryId: async (categoryId) => {
    try {
      const url = `${BASE_URL}products/categories/${categoryId}/subcategories/`;
      console.log('Fetching subcategories from:', url);
      
      const response = await requestInstance.get(url);
      console.log('Raw subcategories response:', response);
      
      if (!response || !response.data) {
        throw new Error('Invalid response format from server');
      }
      
      // If the response data is an array, use it directly
      if (Array.isArray(response.data)) {
        return { results: response.data };
      }
      
      // If the response data has a results property, use that
      if (response.data.results) {
        return response.data;
      }
      
      // If neither format matches, return an empty results array
      return { results: [] };
    } catch (error) {
      console.error(`Failed to fetch subcategories for category ID ${categoryId}:`, error);
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        url: error.config?.url
      });
      // Return empty results instead of throwing to prevent UI from breaking
      return { results: [] };
    }
  },
  
  // Get all tags with optional filtering
  getAllTags: async (params = {}) => {
    try {
      const queryParams = new URLSearchParams();
      
      if (params.categoryId) {
        queryParams.append('category', params.categoryId);
      }
      
      if (params.subcategoryId) {
        queryParams.append('subcategory', params.subcategoryId);
      }
      
      const url = `${BASE_URL}products/tags/?${queryParams.toString()}`;
      console.log('Fetching tags from:', url);
      
      const response = await requestInstance.get(url);
      console.log('Raw tags response:', response);
      
      if (!response || !response.data) {
        throw new Error('Invalid response format from server');
      }
      
      // If the response data is an array, use it directly
      if (Array.isArray(response.data)) {
        return { results: response.data };
      }
      
      // If the response data has a results property, use that
      if (response.data.results) {
        return response.data;
      }
      
      // If neither format matches, return an empty results array
      return { results: [] };
    } catch (error) {
      console.error('Failed to fetch tags:', error);
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        url: error.config?.url
      });
      // Return empty results instead of throwing to prevent UI from breaking
      return { results: [] };
    }
  },
  
  // Get products with category filtering
  getProductsWithCategoryFilters: async (params = {}) => {
    try {
      const queryParams = new URLSearchParams();
      
      if (params.category) {
        queryParams.append('category', params.category);
      }
      
      if (params.subcategory) {
        queryParams.append('subcategory', params.subcategory);
      }
      
      if (params.tag) {
        queryParams.append('tag', params.tag);
      }
      
      // Add other common filters
      if (params.name) {
        queryParams.append('name', params.name);
      }
      
      if (params.currency) {
        queryParams.append('currency', params.currency);
      }
      
      // Pagination
      if (params.limit) {
        queryParams.append('limit', params.limit);
      }
      
      if (params.offset) {
        queryParams.append('offset', params.offset);
      }
      
      const url = `${BASE_URL}products/?${queryParams.toString()}`;
      const response = await requestInstance.get(url);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch products with category filters:', error);
      throw error;
    }
  }
};