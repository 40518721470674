import './Poster.scss'

export const Poster = ({ selected = 'seller' }) => {
  const content = {
    seller: {
      title: "Register your brand and sell",
      buttonText: "Start selling"
    },
    buyer: {
      title: "Register your store or venue and find new craft brands",
      buttonText: "Start buying"
    }
  };

  return (
    <div className="Poster">
      <div className="Poster__overlay">
        <h2 className="Poster__title">{content[selected].title}</h2>
        <button className="Poster__button">{content[selected].buttonText}</button>
      </div>
    </div>
  )
}

export default Poster;