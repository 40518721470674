import './CompanyForm.scss';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getNames } from 'country-list';

import { BASE_URL } from '../../../api/constants';
import { requestInstance } from '../../../services/AuthService/AccessTokenUpdateSevice';

// Name validation functions
const validateName = (name, fieldName) => {
  if (!name) return `${fieldName} is required`;
  if (name.length < 2) return `${fieldName} must be at least 2 characters long`;
  if (name.length > 50) return `${fieldName} must be less than 50 characters`;
  if (!/^[a-zA-Z\s\-']+$/.test(name)) return `${fieldName} can only contain letters, spaces, hyphens, and apostrophes`;
  if (/^\s|\s$/.test(name)) return `${fieldName} cannot start or end with spaces`;
  return null;
};

export const CompanyForm = ({
  setIsUserFormVisible,
  setIsRegisterFormVisible,
  setIsLoginFormVisible
}) => {
  const [formData, setFormData] = useState({
    type_of_business: 'brand',
    first_name: '',
    last_name: '',
    email: '',
    brand_name: '',
    phone: '+',
    country: '',
    language: 'English',
    company_size: 'small',
    primary_interests: 'brand owner',
  });
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [isOtherSelected, setIsOtherSelected] = useState(false); // Для отображения поля ввода
  const [otherInputValue, setOtherInputValue] = useState(''); // Значение пользовательского ввода
  const [fullNameInput, setFullNameInput] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };
    let updatedFormData = { ...formData };

    // Clear previous error for this field
    delete newErrors[name];

    // Validate names on change
    if (name === 'first_name' || name === 'last_name') {
      const fieldName = name === 'first_name' ? 'First name' : 'Last name';
      const error = validateName(value, fieldName);
      if (error) {
        newErrors[name] = [error];
      }
    } else if (name === 'type_of_business') {
      // Reset primary_interests when business type changes
      updatedFormData = { ...formData, [name]: value, primary_interests: '' }; 
      setIsOtherSelected(false); // Also reset 'other' field display
      setOtherInputValue('');
    } else if (name === 'primary_interests') {
      if (value === 'other') {
        setIsOtherSelected(true);
        updatedFormData = { ...formData, primary_interests: '' }; // Clear main field
      } else {
        setIsOtherSelected(false);
        updatedFormData = { ...formData, primary_interests: value }; // Update with selected value
      }
    } else {
      // Handle other field changes
      updatedFormData = { ...formData, [name]: value };
    }

    setFormData(updatedFormData);
    setErrors(newErrors);
  };
  const handleOtherInputChange = (e) => {
    const value = e.target.value;
    setOtherInputValue(value);
    setFormData({ ...formData, primary_interests: value }); // Обновляем `primary_interests` пользовательским значением
  };

  const getPrimaryInterestsOptions = () => {
    switch (formData.type_of_business) {
      case 'brand':
        return [
          { value: 'brand owner', label: 'Brand Owner' },
          { value: 'manufacturer', label: 'Manufacturer' },
          { value: 'brand representative', label: 'Brand Representative' },
          { value: 'other', label: 'Other: write in' },
        ];
      case 'buyer':
        return [
          { value: 'hotel', label: 'Hotel' },
          { value: 'restaurant', label: 'Restaurant' },
          { value: 'bar', label: 'Bar' },
          { value: 'club', label: 'Club' },
          { value: 'event manager', label: 'Event Manager' },
          { value: 'grocery store', label: 'Grocery Store' },
          { value: 'liquor store', label: 'Liquor Store' },
          { value: 'other retailer', label: 'Other Retailer' },
          { value: 'cruise line', label: 'Cruise Line' },
          { value: 'other', label: 'Other: write in' },
        ];
      /* case 'complementor':
        return [
          { value: 'designer', label: 'Designer' },
          { value: 'other', label: 'Other: write in' },
        ]; */
      default:
        return [];
    }
  };

  const validateFullName = (fullName) => {
    if (!fullName) return "Full name is required";
    const parts = fullName.trim().split(/\s+/);
    
    if (parts.length < 2) return "Please enter both first and last name";
    if (parts.some(part => part.length < 2)) return "Each name part must be at least 2 characters long";
    if (parts.some(part => part.length > 50)) return "Each name part must be less than 50 characters";
    if (parts.some(part => !/^[a-zA-Z\-']+$/.test(part))) return "Names can only contain letters, hyphens, and apostrophes";
    
    return null;
  };

  const handleFullNameChange = (e) => {
    const fullName = e.target.value;
    const validationError = validateFullName(fullName);
    
    // Clear previous name-related errors
    const newErrors = { ...errors };
    delete newErrors.first_name;
    delete newErrors.last_name;
    delete newErrors.full_name;

    if (validationError) {
      newErrors.full_name = [validationError];
      setErrors(newErrors);
    } else {
      const nameParts = fullName.trim().split(/\s+/);
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(' ');
      setFormData({ ...formData, first_name: firstName, last_name: lastName });
      setErrors(newErrors);
    }
  };

  const allCountries = getNames().map((country) => ({
    value: country,
    label: country,
  }));

  const handleCountryChange = (selectedOption) => {
    setFormData({ ...formData, country: selectedOption ? selectedOption.value : '' });
  };

  const filterCountries = (inputValue) => {
    return allCountries.filter((country) =>
      country.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadCountryOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterCountries(inputValue));
    }, 1000); 
  };

  const validateForm = () => {
    const newErrors = {};
    
    // Validate full name
    const fullName = `${formData.first_name} ${formData.last_name}`.trim();
    const nameError = validateFullName(fullName);
    if (nameError) {
      newErrors.full_name = [nameError];
    }

    // Add other validations here...

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form before submission
    if (!validateForm()) {
      return;
    }

    try {
      const url = BASE_URL + 'auth/registration-1-step/';
      console.log(formData)
      const response = await requestInstance.post(url, formData);
      localStorage.setItem('companyID', response.data.id);
      localStorage.setItem('email', response.data.email);
      console.log('Form Data:', formData);
      console.log('Response:', response.data);

      setIsRegisterFormVisible(false)
      setIsUserFormVisible(true)
    } catch (error) {
      console.error('Registration error:', {
        status: error.response?.status,
        data: error.response?.data,
        headers: error.response?.headers,
        formData: formData
      });
      if (error.response && error.response.data) {
        if (error.response.data.error) {
          setErrors({ general: error.response.data.error });
        } else {
          setErrors(error.response.data);
        }
      }
    }
  };
  
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div className="SignUpForm-overlay">
      <div className="SignUpForm">
        <h2 className="SignUpForm__title">
          Sign Up
          <span className='closeForm' onClick={() => setIsRegisterFormVisible(false)}>×</span>
        </h2>
        <form className="SignUpForm__form" onSubmit={handleSubmit}>
          <div className="SignUpForm__input-group">
            <label htmlFor="type_of_business">I am a...</label>
            <div className="select-wrapper">
              <select
                name="type_of_business"
                value={formData.type_of_business}
                onChange={handleChange}
              >
                <option value="brand">Brand</option>
                <option value="buyer">Buyer</option>
                {/* <option value="complementor">Complementor</option> */}
              </select>
            </div>
          </div>
          <div className="SignUpForm__input-group">
            <label htmlFor="full_name">First name and last name</label>
            <input
              type="text"
              name="full_name"
              value={fullNameInput}
              onChange={(e) => {
                setFullNameInput(e.target.value);
                handleFullNameChange(e);
              }}
              placeholder="Enter your full name (e.g., John Smith)"
              required
            />
            {errors.full_name && <p className="ErrorText">{errors.full_name[0]}</p>}
          </div>
          <div className="SignUpForm__input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className="ErrorText">{errors.email[0]}</p>}
          </div>

          <div className="SignUpForm__input-group__container">
            <div className="SignUpForm__input-group SignUpForm__input-group--half">
              <label htmlFor="company_name">Company name</label>
              <input
                type="text"
                name="brand_name"
                value={formData.brand_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="SignUpForm__input-group SignUpForm__input-group--half">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {errors.phone && <p className="ErrorText">{errors.phone[0]}</p>}
            </div>
          </div>

          <div className="SignUpForm__input-group__container">
            <div className="SignUpForm__input-group SignUpForm__input-group--half Contry__select">
              <label htmlFor="country">Country</label>
              <AsyncSelect
                name="country"
                value={allCountries.find(
                  (option) => option.value === formData.country
                )}
                onChange={handleCountryChange}
                loadOptions={loadCountryOptions}
                defaultOptions
                cacheOptions
                menuPlacement="auto"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '250px', 
                    overflowY: 'auto' 
                  }),
                }}
                required
                />
            </div>
            <div className="SignUpForm__input-group SignUpForm__input-group--half">
              <label htmlFor="language">Language</label>
              <div className="select-wrapper">
              <select
                name="language"
                value={formData.language}
                onChange={handleChange}
                required
              >
                <option value='English'>English</option>
                <option value='German'>German</option>
                <option value='French'>French</option>
                <option value='Spanish'>Spanish</option>
                <option value='Italian'>Italian</option>
                <option value='Norwegian'>Norwegian</option>
              </select>
              </div>
            </div>
          </div>

          <div className="SignUpForm__input-group__container">
            <div className="SignUpForm__input-group SignUpForm__input-group--half">
              <label htmlFor="company_size">Company size</label>
              <div className="select-wrapper">
              <select
                name="company_size"
                value={formData.company_size}
                onChange={handleChange}
                required
              >
                  <option value='small'>Small</option>
                  <option value='medium'>Medium</option>
                  <option value='large'>Large</option>
              </select>
              </div>
            </div>
            <div className="SignUpForm__input-group SignUpForm__input-group--half">
              <label htmlFor="primary_interests">Are you...</label>
              <div className="select-wrapper">
          <select
            name="primary_interests"
            value={isOtherSelected ? 'other' : formData.primary_interests}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select an option
            </option>
            {getPrimaryInterestsOptions().map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        {/* </div> */}

              </div>
            </div>

          </div>

          {isOtherSelected && (
            <div className="SignUpForm__input-group">
              <label htmlFor="other_interest">Please specify:</label>
              <input
                type="text"
                name="other_interest"
                value={otherInputValue}
                onChange={handleOtherInputChange}
                required
              />
            </div>
          )}

          <div className="SignUpForm__privacy">
          <input
            type="checkbox"
            id="improve_experience"
            onChange={handleCheckboxChange}
          />
          <label htmlFor="improve_experience">
            I want to help improve the user experience by recording the start of my trial session 
            <a href="#how"> (how?)</a>
          </label>
        </div>
        
        <div className="SignUpForm__terms">
          <p>
            By clicking on <strong>Start Now</strong>, you accept our 
            <a href="#subscription"> Subscription Agreement</a> and 
            <a href="#privacy"> Privacy Policy</a>.
          </p>
        </div>

        {errors.general && <p className="ErrorText">{errors.general}</p>}

        <button
          type="submit"
          className="SignUpForm__submit"
        >
          Next step
        </button>
        
        </form>
        <div className="SignUpForm__footer">
          <p>
            Already have an account?
            <a href="#login" className="SignUpForm__login" onClick={() => {
              setIsLoginFormVisible(true)
              setIsRegisterFormVisible(false)
            }}> Log In</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompanyForm;
