import './Benefits.scss';
import { Toggle } from '../../../components/Toggle/Toggle';
import { useState } from 'react';

// Import seller images
import sellerHoreca from './images/seller/horeca.png';
import sellerLogistics from './images/seller/logistics.png';
import sellerSales from './images/seller/sales.png';
import sellerEasier from './images/seller/easier.png';

// Import buyer images
import buyerCraft from './images/buyer/craft.png';
import buyerTenders from './images/buyer/tenders.png';
import buyerPremium from './images/buyer/premium.png';
import buyerEasier from './images/buyer/easier.png';

const BENEFITS_DATA = {
  seller: [
    {
      title: 'Only Place To Reach Horeca Customer',
      text: 'Enter the only active marketplace that connect brands with horeca customers across Europe and receive orders directly from buyers. Easy ordering, credit and payment solutions.',
      image: sellerHoreca
    },
    {
      title: 'Pan European Logistic Solution',
      text: 'Get access to low cost central storage logistic with fully compliant excise solution across European countries. Send a small order directly to a Hotel in Vianna.',
      image: sellerLogistics
    },
    {
      title: 'Increase sales, margins & markets served',
      text: 'Save 20%, 30% or more in mark-ups and costs. Share savings with resellers - end-users buy more. Start selling in many European countries at once! Plus benefit from our loyalty program.',
      image: sellerSales
    },
    {
      title: 'Make Your Life Easier',
      text: 'Trade show lost cost cooperation, data analytics, brand ambassadors, marketing solutions, curated buyer meetings and sampling solutions.',
      image: sellerEasier
    }
  ],
  buyer: [
    {
      title: 'The best place to find new craft brands',
      text: 'Find exciting brands from all over the world to make your portfolio even more attractive for your customers. Shop across many brands with NO or low minimum order!',
      image: buyerCraft
    },
    {
      title: 'Create tenders',
      text: 'Make brands work for you – use Tyrrst Tenders to ask brands and distilleries for customized solutions, samples, special offers, etc.',
      image: buyerTenders
    },
    {
      title: 'Buy premium products for less',
      text: 'Engage with brands directly on Tyrrst and save 5%, 8%, 10% or more through lower prices, get free shipping with low minimum orders, trade financing up to 90 days (or further discounts!) and the occasional free samples…',
      image: buyerPremium
    },
    {
      title: 'Make Your Life Easier',
      text: 'No matter how many brands you buy from, benefit from a single invoice and NO HASSLE delivery – all import and customs formalities are taken care off by Tyrrst!',
      image: buyerEasier
    }
  ]
};

export const Benefits = ({ selected, setSelected }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="Benefits">
      <h2 className="Benefits__title">Benefits</h2>
      <Toggle selected={selected} setSelected={setSelected} />
      <p className="Benefits__description">
        {selected === 'buyer' 
          ? "Discover the perfect products for your customers by connecting directly with hundreds of top craft brands worldwide. Enjoy low minimum orders, hassle-free customs-cleared deliveries, and the convenience of a single invoice"
          : "The ultimate platform for expanding into new markets faster and boosting sales by connecting directly with resellers. Our seamlessly integrated apps simplify logistics, excise, and more—ensuring effortless cross-border shipping across Europe."}
      </p>
      <div className="Benefits__box">
        <div className="Benefits__content">
          <ul className="Benefits__list">
            {BENEFITS_DATA[selected].map((benefit, index) => (
              <li 
                key={index} 
                className={`Benefits__list__element ${index === activeIndex ? 'Benefits__list__element--active' : ''}`}
                onMouseEnter={() => setActiveIndex(index)}
              >
                <h3 className="Benefits__list__element__title">
                  {benefit.title}
                </h3>
                <p className="Benefits__list__element__text">
                  {benefit.text}
                </p>
              </li>
            ))}
          </ul>
          <div className="Benefits__image-container">
            <img 
              src={BENEFITS_DATA[selected][activeIndex].image} 
              className="Benefits__image" 
              alt={BENEFITS_DATA[selected][activeIndex].title} 
            />
          </div>
        </div>
      </div>
    </div>
  )
};

export default Benefits;