import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ProductForm.scss';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../../../api/constants';
import AsyncSelect from 'react-select/async';
import ImageForm from '../../../components/ImageForm/ImageForm';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CategorySelector } from '../../../components/CategorySelector/CategorySelector';

export const ProductForm = ({ setCurrentForm, productId = null }) => {
  const [product, setProduct] = useState({
    name: '',
    alcohol_percentage: 0,
    bottle_size: '',
    production_year: new Date().getFullYear(),
    age: 0,
    proven_sustainable_product_line: false,
    available_for_sampling_service: false,
    price: 0,
    payment_conditions: '',
    numbers_in_stock: 0,
    description: '',
    makers: '',
    category_id: null,
    company_id: JSON.parse(localStorage.getItem('user')).company.id,
    warehouses_contain_2: [],
    warehouses_contain: [],
    currency: 'EUR',
    subcategories: [],
    tags: [],
  });

  const [initialProduct] = useState({
    name: '',
    alcohol_percentage: 0,
    bottle_size: '',
    production_year: new Date().getFullYear(),
    age: 0,
    proven_sustainable_product_line: false,
    available_for_sampling_service: false,
    price: 0,
    payment_conditions: '',
    numbers_in_stock: 0,
    description: '',
    makers: '',
    category_id: null,
    company_id: JSON.parse(localStorage.getItem('user')).company.id,
    warehouses_contain_2: [],
    warehouses_contain: [],
    currency: 'EUR',
    subcategories: [],
    tags: [],
  });

  const [warehouses, setWarehouses] = useState([]);
  const [productPicture, setProductPicture] = useState({});
  const fileInputRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const warehousesUrl = `${BASE_URL}ware-houses/v2/?company_id=${product.company_id}`;
        console.log('Fetching warehouses from:', warehousesUrl);
        const warehousesResponse = await requestInstance.get(warehousesUrl);
        const warehousesData = warehousesResponse.data.results || [];
        console.log('Available warehouses:', warehousesData);
        setWarehouses(warehousesData);

        if (productId) {
          const productUrl = `${BASE_URL}products/${productId}/`;
          const productResponse = await requestInstance.get(productUrl);
          setProduct(productResponse.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
        toast.error('Failed to fetch data');
      }
    };

    fetchData();
  }, [productId, product.company_id]);

  const handleCategorySelect = (categoryId) => {
    setProduct(prev => ({
      ...prev,
      category_id: categoryId,
      subcategories: [], // Reset subcategories when category changes
      tags: [], // Reset tags when category changes
    }));
    setError(null); // Clear any previous errors
  };

  const handleSubcategorySelect = (subcategoryIds) => {
    setProduct(prev => ({
      ...prev,
      subcategories: subcategoryIds.filter(Boolean), // Filter out any null/undefined values
      tags: [], // Reset tags when subcategory changes
    }));
  };

  const handleTagSelect = (tagIds) => {
    setProduct(prev => ({
      ...prev,
      tags: tagIds.filter(Boolean), // Filter out any null/undefined values
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setProduct(prev => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleCheckboxChangeW = (selectedOptions) => {
    // Convert selectedOptions to array of warehouse IDs (as numbers)
    const selectedIds = selectedOptions ? selectedOptions.map(option => Number(option.value)) : [];
    
    // Validate each selected warehouse exists in our warehouses list
    const validWarehouses = selectedIds.filter(id => warehouses.some(w => w.id === id));
    
    if (validWarehouses.length !== selectedIds.length) {
      const invalidCount = selectedIds.length - validWarehouses.length;
      toast.warning(`${invalidCount} selected warehouse(s) are no longer available`);
    }

    console.log('Setting warehouses_contain to:', validWarehouses);
    setProduct(prev => ({
      ...prev,
      warehouses_contain: validWarehouses,
      warehouses_contain_2: validWarehouses, // Keep both arrays in sync
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProductPicture({
        file,
        preview: URL.createObjectURL(file)
      });
    }
  };

  const loadWarehouses = async (inputValue) => {
    try {
      const response = await requestInstance.get(
        `${BASE_URL}ware-houses/v2/?company_id=${product.company_id}&search=${inputValue}`
      );
      const warehousesData = response.data.results || [];
      
      // Update the warehouses state with the latest data
      setWarehouses(prev => {
        const newWarehouses = [...prev];
        warehousesData.forEach(warehouse => {
          const existingIndex = newWarehouses.findIndex(w => w.id === warehouse.id);
          if (existingIndex === -1) {
            newWarehouses.push(warehouse);
          } else {
            newWarehouses[existingIndex] = warehouse;
          }
        });
        return newWarehouses;
      });

      return warehousesData.map(warehouse => ({
        value: warehouse.id,
        label: warehouse.name
      }));
    } catch (error) {
      console.error('Error loading warehouses:', error);
      toast.error('Failed to load warehouses');
      return [];
    }
  };

  const handleDescriptionChange = (content) => {
    setProduct(prev => ({
      ...prev,
      description: content
    }));
  };

  const handleSave = async () => {
    try {
      // Enhanced validation
      const validationErrors = [];
      
      if (!product.name.trim()) {
        validationErrors.push('Product name is required');
      }
      
      if (!product.category_id) {
        validationErrors.push('Please select a category');
      }
      
      if (!product.subcategories.length) {
        validationErrors.push('Please select at least one subcategory');
      }
      
      if (product.price < 0) {
        validationErrors.push('Price cannot be negative');
      }
      
      if (product.numbers_in_stock < 0) {
        validationErrors.push('Stock quantity cannot be negative');
      }

      // Validate warehouses
      const validWarehouses = product.warehouses_contain
        .map(id => typeof id === 'string' ? parseInt(id, 10) : id)
        .filter(id => warehouses.some(w => w.id === id));

      if (validWarehouses.length === 0) {
        validationErrors.push('Please select at least one warehouse');
      }

      if (validationErrors.length > 0) {
        validationErrors.forEach(error => toast.error(error));
        return;
      }

      const formData = new FormData();
      
      // Handle arrays and objects properly
      Object.keys(product).forEach(key => {
        if (Array.isArray(product[key])) {
          if (key === 'warehouses_contain' || key === 'warehouses_contain_2') {
            // Ensure warehouse IDs are valid numbers
            const validIds = validWarehouses;
            console.log(`Adding ${key}:`, validIds);
            validIds.forEach(id => {
              formData.append(key, id.toString());
            });
          } else {
            // Handle other arrays (subcategories, tags)
            product[key]
              .map(value => typeof value === 'string' ? parseInt(value, 10) : value)
              .filter(value => !isNaN(value))
              .forEach(value => {
                formData.append(key, value.toString());
              });
          }
        } else if (typeof product[key] === 'object' && product[key] !== null) {
          formData.append(key, JSON.stringify(product[key]));
        } else if (product[key] !== null && product[key] !== undefined) {
          formData.append(key, product[key].toString());
        }
      });

      if (productPicture.file) {
        formData.append('image', productPicture.file);
      }

      const url = productId 
        ? `${BASE_URL}products/${productId}/`
        : `${BASE_URL}products/`;

      // Log the FormData contents for debugging
      console.log('Submitting product with warehouses:', validWarehouses);
      formData.forEach((value, key) => {
        console.log(`${key}:`, value);
      });

      const response = await requestInstance({
        method: productId ? 'PATCH' : 'POST',
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200 || response.status === 201) {
        toast.success(`Product ${productId ? 'updated' : 'created'} successfully`);
        setCurrentForm('list');
      }
    } catch (error) {
      console.error('Error saving product:', error);
      const errorMessage = error.response?.data?.message || 
                          error.response?.data?.error || 
                          'Failed to save product. Please try again.';
      toast.error(errorMessage);
      setError(errorMessage);
    }
  };

  return (
    <div className="product-form">
      <ToastContainer />
      <h2>{productId ? 'Edit Product' : 'Add New Product'}</h2>
      
      {error && (
        <div className="product-form__error">
          {error}
        </div>
      )}
      
      <div className="form-group">
        <label htmlFor="name">Name *</label>
        <input
          type="text"
          id="name"
          name="name"
          value={product.name}
          onChange={handleChange}
          required
          className={!product.name.trim() ? 'error' : ''}
        />
      </div>

      <div className="form-group">
        <label>Category *</label>
        <CategorySelector
          onCategorySelect={handleCategorySelect}
          onSubcategorySelect={handleSubcategorySelect}
          onTagSelect={handleTagSelect}
          selectedCategory={product.category_id}
          selectedSubcategories={product.subcategories}
          selectedTags={product.tags}
          disabled={false}
        />
      </div>

      <div className='ProductForm__section'>
        {productId ? <ImageForm objectId={productId} objectType='product' /> :

          <div className='CocktailForm__row'>
            <div className='CocktailForm__field'>
              <h3 className='CocktailForm__field_title'>Picture</h3>
              <input
                className='CocktailForm__input'
                type='file'
                name='picture'
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            </div>
          </div>
        }
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Alcohol Percentage</h3>
            <input className='ProductForm__input' type='number' name='alcohol_percentage' value={product.alcohol_percentage} onChange={handleChange} />
          </div>
        </div>
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Bottle Size</h3>
            <input className='ProductForm__input' name='bottle_size' value={product.bottle_size} onChange={handleChange} />
          </div>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Price to Horeca</h3>
            <input className='ProductForm__input' min="0" type='number' name='price' value={product.price} onChange={handleChange} />
          </div>
        </div>
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Production Year</h3>
            <input className='ProductForm__input' type='number' name='production_year' value={product.production_year} onChange={handleChange} />
          </div>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Age</h3>
            <input className='ProductForm__input' type='number' name='age' value={product.age} onChange={handleChange} />
          </div>
        </div>
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Numbers in Stock</h3>
            <input className='ProductForm__input' type='number' name='numbers_in_stock' value={product.numbers_in_stock} onChange={handleChange} />
          </div>

          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Currency</h3>
            <select 
              className='ProductForm__input' 
              name='currency' 
              value={product.currency} 
              onChange={handleChange}
            >
              <option value="EUR">Euro (EUR)</option>
              <option value="USD">Dollar (USD)</option>
              <option value="NOK">Norwegian Krone (NOK)</option>
              <option value="DKK">Danish Krone (DKK)</option>
              <option value="GBP">Pound Sterling (GBP)</option>
              <option value="SEK">Swedish Krona (SEK)</option>
              <option value="PLN">Polish Zloty (PLN)</option>
              <option value="CZK">Czech Koruna (CZK)</option>
              <option value="HUF">Hungarian Forint (HUF)</option>
              <option value="RON">Romanian Leu (RON)</option>
              <option value="HRK">Croatian Kuna (HRK)</option>
              <option value="BGN">Bulgarian Lev (BGN)</option>
            </select>
          </div>

        </div>
        <div className='ProductForm__row'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Makers</h3>
            <input className='ProductForm__input' name='makers' value={product.makers} onChange={handleChange} />
          </div>
        </div>
        
        <div className='ProductForm__row ProductForm__field--long'>
          <div className='ProductForm__field'>
            <h3 className='ProductForm__field_title'>Select from which warehouse the product ships</h3>
            <AsyncSelect
              isMulti
              cacheOptions
              defaultOptions={warehouses.map(warehouse => ({ value: warehouse.id, label: warehouse.name }))}
              loadOptions={loadWarehouses}
              onChange={handleCheckboxChangeW}
              value={warehouses.filter(warehouse => product.warehouses_contain.includes(warehouse.id)).map(warehouse => ({ value: warehouse.id, label: warehouse.name }))}
            />
          </div>
        </div>
        <div className='ProductForm__field--description'>
          <div className='ProductForm__field' style={{ width: '92%' }}>
            <h3 className='ProductForm__field_title'>Description</h3>
            <ReactQuill
              theme="snow"
              value={product.description}
              onChange={handleDescriptionChange}
            />
          </div>
        </div>
        <div className='ProductForm--checkbox'>
          <div className='ProductForm__field--checkbox'>
            <h3 className='ProductForm__field_title'>Proven Sustainable Product Line</h3>
            <input className='ProductForm__input--checkbox' type='checkbox' name='proven_sustainable_product_line' checked={product.proven_sustainable_product_line} onChange={handleCheckboxChange} />
          </div>
          <div className='ProductForm__field--checkbox' >
            <h3 className='ProductForm__field_title'>Available for Sampling Service</h3>
            <input className='ProductForm__input--checkbox' type='checkbox' name='available_for_sampling_service' checked={product.available_for_sampling_service} onChange={handleCheckboxChange} />
          </div>
        </div>
      </div>
      <div className='ProductForm__header'>
        <h2 className='ProductForm__title'>{productId ? 'Update Product' : 'Create Product'}</h2>
        <div className='ProductForm__header__buttons'>
          <button className='ProductForm__header__button ProductForm__header__button--cancel' onClick={() => setCurrentForm('products')}>Back</button>
          <button className='ProductForm__header__button ProductForm__header__button--save' onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;