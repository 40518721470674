import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import debounce from 'lodash/debounce';
import { categoryService } from '../../api/categoryService';
import { useUser } from '../../context/UserContext';

export const SearchableCategorySelect = ({
  value,
  onChange,
  error,
  helperText,
  label = 'Category',
  placeholder = 'Search for a category...',
  required = false,
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const { user } = useUser();
  const categoriesCache = useRef(new Map());

  // Function to get error message based on error type
  const getErrorMessage = useCallback((error) => {
    if (error?.response?.status === 404) {
      return 'Categories not found';
    }
    if (error?.response?.status === 403) {
      return 'You do not have permission to view categories';
    }
    if (error?.message?.includes('Network')) {
      return 'Network error. Please check your connection';
    }
    return 'Failed to load categories';
  }, []);

  // Load initial categories with caching
  const loadInitialCategories = useCallback(async () => {
    // Use cached results if available
    if (categoriesCache.current.has('initial')) {
      setOptions(categoriesCache.current.get('initial'));
      return;
    }

    try {
      setLoading(true);
      setSearchError(null);
      const response = await categoryService.getAllCategories({
        limit: 100
      });
      
      const results = response.results || [];
      setOptions(results);
      categoriesCache.current.set('initial', results);
    } catch (err) {
      console.error('Error loading initial categories:', err);
      setSearchError(getErrorMessage(err));
      setOptions([]);
    } finally {
      setLoading(false);
    }
  }, [getErrorMessage]);

  // Load initial categories on mount
  useEffect(() => {
    loadInitialCategories();
  }, [loadInitialCategories]);

  // Optimized debounced search with caching
  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchTerm) => {
        if (!searchTerm) {
          return;
        }

        // Check cache first
        const cacheKey = `search:${searchTerm}`;
        if (categoriesCache.current.has(cacheKey)) {
          setOptions(categoriesCache.current.get(cacheKey));
          return;
        }

        try {
          setLoading(true);
          setSearchError(null);
          const response = await categoryService.getAllCategories({
            name: searchTerm
          });
          
          const results = response.results || [];
          setOptions(results);
          
          // Cache the results
          categoriesCache.current.set(cacheKey, results);
          
          // Clear old cache entries if cache gets too large
          if (categoriesCache.current.size > 50) {
            const firstKey = categoriesCache.current.keys().next().value;
            categoriesCache.current.delete(firstKey);
          }
        } catch (err) {
          console.error('Error searching categories:', err);
          setSearchError(getErrorMessage(err));
          // Don't clear options on error to keep previous results
        } finally {
          setLoading(false);
        }
      }, 300),
    [getErrorMessage]
  );

  // Handle input change with cleanup
  useEffect(() => {
    if (inputValue) {
      debouncedSearch(inputValue);
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [inputValue, debouncedSearch]);

  // Clear cache when component unmounts
  useEffect(() => {
    return () => {
      categoriesCache.current.clear();
    };
  }, []);

  // Find the selected category in options
  const selectedCategory = useMemo(() => {
    if (!value) return null;
    return options.find(option => option.id === value) || null;
  }, [value, options]);

  return (
    <Autocomplete
      value={selectedCategory}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      getOptionLabel={(option) => option?.name || ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      loading={loading}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={!!error || !!searchError}
          helperText={error || searchError || helperText}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      noOptionsText={searchError || "No categories found"}
    />
  );
}; 