import React from 'react';
import './Toggle.scss';

export const Toggle = ({ selected, setSelected }) => {
  return (
    <div className={`toggle-container ${selected === 'buyer' ? 'buyer-active' : ''}`}>
      <div
        className={`toggle-option ${selected === 'seller' ? 'active' : ''}`}
        onClick={() => setSelected('seller')}
      >
        Seller
      </div>
      <div
        className={`toggle-option ${selected === 'buyer' ? 'active' : ''}`}
        onClick={() => setSelected('buyer')}
      >
        Buyer
      </div>
    </div>
  );
};

export default Toggle;