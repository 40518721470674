import './Features.scss';

const FEATURES_DATA = {
  seller: [
    {
      title: 'Easy access',
      text: 'Access a broader range of customers across Europe and grow your business with our easy-to-use solutions for producers and resellers, no matter how big or small.'
    },
    {
      title: 'Easy payment',
      text: 'Never send an invoice or chase a dollar of payment again and say goodbye to those sleepless nights worrying about cashflow.'
    },
    {
      title: 'Easy fulfilling',
      text: 'Specialised solutions with bonded storage, order pick up, small batch customs clearing, shipping direct to customers, pick pack and ship service.'
    },
    {
      title: 'Easy ordering',
      text: 'Manage all of your customers\' orders in one place with no more messy emails, phone calls or texts.'
    },
    {
      title: 'Easy account management',
      text: 'Reduce friction in your sales process and remove those time consuming credit apps.'
    },
    {
      title: 'Easy reporting',
      text: 'Keep up to date and better manage your sales operations with real-time reporting.'
    },
    {
      title: 'Easy growth',
      text: 'Tyrrst is unique in helping you grow in HORECA as well grocery - and gives you a wealth of information on your target customers.'
    }
  ],
  buyer: [
    {
      title: 'Easy search',
      text: 'Search for brands with easy to use filters for name, origin, category and/or benefit from Tyrrst recommendations – just do an empty search!'
    },
    {
      title: 'Easy communication',
      text: 'Connect with brands and their team with direct message'
    },
    {
      title: 'Reach your own customers',
      text: 'Tyrrst premium allows you to turn your profile into a full fledged customer facing website'
    },
    {
      title: 'Easy ordering',
      text: 'Place orders directly through the platform with just a few clicks'
    },
    {
      title: 'Easy payment',
      text: 'Secure payment processing with flexible payment terms'
    },
    {
      title: 'Easy tracking',
      text: 'Track your orders and inventory in real-time'
    },
    {
      title: 'Easy growth',
      text: 'Expand your portfolio with unique products from around the world'
    }
  ]
};

export const Features = ({ selected }) => {
  return (
    <div className="Features">
      <h2 className="Features__title">Features</h2>
      <ul className="Features__list">
        {FEATURES_DATA[selected].map((feature, index) => (
          <li key={index} className={`Features__list__item ${index === 0 ? 'Features__list__item--large' : ''}`}>
            <h3 className="Features__list__item__title">{feature.title}</h3>
            <p className="Features__list__item__text">
              {feature.text.split('\n').map((line, i) => (
                <span key={i}>
                  {line}
                  <br />
                </span>
              ))}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Features;