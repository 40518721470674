import { BASE_URL } from '../../../api/constants';
import requestInstance from '../../../services/AuthService/AccessTokenUpdateSevice';
import './CompanyForm.scss';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getNames } from 'country-list';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const CompanyForm = () => {
  const [company, setCompany] = useState(null);
  const [originalCompany, setOriginalCompany] = useState(null);
  const userInfo = JSON.parse(localStorage.getItem('user'));
  const company_id = userInfo?.company?.id;

  useEffect(() => {
    if (!company_id) {
      console.error('No company ID found');
      return;
    }

    const getCompany = async () => {
      try {
        const url = `${BASE_URL}company/${company_id}/`;
        const response = await requestInstance.get(url);
        setCompany(response.data);
        setOriginalCompany(response.data);
      } catch (error) {
        console.error('Error fetching company data:', error);
        toast.error('Failed to load company data');
      }
    };
    getCompany();
  }, [company_id]);

  const allCountries = getNames().map((country) => ({
    value: country,
    label: country,
  }));

  const companySizes = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
  ];

  const languages = [
    { value: 'English', label: 'English' },
    { value: 'German', label: 'German' },
    { value: 'French', label: 'French' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'Italian', label: 'Italian' },
    { value: 'Norwegian', label: 'Norwegian' },
  ];
  const currencies = [
    { value: 'EUR', label: 'Euro (EUR)' },
    { value: 'USD', label: 'Dollar (USD)' },
    { value: 'NOK', label: 'Norwegian Krone (NOK)' },
    { value: 'DKK', label: 'Danish Krone (DKK)' },
    { value: 'GBP', label: 'Pound Sterling (GBP)' },
    { value: 'SEK', label: 'Swedish Krona (SEK)' },
    { value: 'PLN', label: 'Polish Zloty (PLN)' },
    { value: 'CZK', label: 'Czech Koruna (CZK)' },
    { value: 'HUF', label: 'Hungarian Forint (HUF)' },
    { value: 'RON', label: 'Romanian Leu (RON)' },
    { value: 'HRK', label: 'Croatian Kuna (HRK)' },
    { value: 'BGN', label: 'Bulgarian Lev (BGN)' },
  ];

  const handleCurrencyChange = (selectedOption) => {
    setCompany({ ...company, account_currency: selectedOption ? selectedOption.value : '' });
  };

  const handleCountryChange = (selectedOption) => {
    setCompany({ ...company, country: selectedOption ? selectedOption.value : '' });
  };

  const handleCompanySizeChange = (selectedOption) => {
    setCompany({ ...company, company_size: selectedOption ? selectedOption.value : '' });
  };

  const handleLanguageChange = (selectedOption) => {
    setCompany({ ...company, language: selectedOption ? selectedOption.value : '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompany({ ...company, [name]: value });
  };

  const handleCancel = () => {
    setCompany(originalCompany);
  };

  const handleSave = async () => {
    try {
      const url = `${BASE_URL}company/${company_id}/`;
      const response = await requestInstance.patch(url, company);
      setOriginalCompany(company); 
      toast.success('Information saved successfully!');
    } catch (error) {
      if (error.response && error.response.data.errors) {
        const errors = error.response.data.errors;
        for (const [field, message] of Object.entries(errors)) {
          toast.error(`${field}: ${message}`);
        }
      } else {
        toast.error('Failed to save information.');
      }
      console.error(error);
    }
  };

  const filterOptions = (options) => (inputValue) => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (options) => (inputValue, callback) => {
    setTimeout(() => {
      callback(filterOptions(options)(inputValue));
    }, 1000); 
  };

  return (
    <div className='CompanyForm'>
      <ToastContainer />
      {company === null ? (
        <div>Loading...</div>
      ) : (
        <>
          <div>
            <div className='CompanyForm__header'>
              <h2 className='CompanyForm__title'>Account</h2>
              <div className='CompanyForm__header__buttons'>
                <button className='CompanyForm__header__button CompanyForm__header__button--cancel' onClick={handleCancel}>Cancel</button>
                <button className='CompanyForm__header__button CompanyForm__header__button--save' onClick={handleSave}>Save</button>
              </div>
            </div>
            <div className='CompanyForm__section'>
              <div className='CompanyForm__row'>
                <div className='CompanyForm__field'>
                  <h3 className='CompanyForm__field_title'>Company name</h3>
                  <input
                    className='CompanyForm__input'
                    name='brand_name'
                    value={company?.brand_name || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className='CompanyForm__field'>
                  <h3 className='CompanyForm__field_title'>Website</h3>
                  <input
                    className='CompanyForm__input'
                    name='website'
                    value={company?.website || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className='CompanyForm__row'>
              <div className='CompanyForm__field'>
                <h3 className='CompanyForm__field_title'>Country</h3>
                <div className='CompanyForm__select-wrapper'>
                  <AsyncSelect
                    name="country"
                    value={allCountries.find((option) => option.value === company.country)}
                    onChange={handleCountryChange}
                    loadOptions={loadOptions(allCountries)}
                    defaultOptions
                    cacheOptions
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        maxHeight: '250px',
                        overflowY: 'auto',
                      }),
                      menuPortal: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                  />
                </div>
              </div>
              <div className='CompanyForm__field'>
                <h3 className='CompanyForm__field_title'>Language</h3>
                <div className='CompanyForm__select-wrapper'>
                  <AsyncSelect
                    name="language"
                    value={languages.find(
                      (option) => option.value === company.language
                    )}
                    onChange={handleLanguageChange}
                    loadOptions={loadOptions(languages)}
                    defaultOptions
                    cacheOptions
                  />
                </div>
              </div>
            </div>
            <div className='CompanyForm__row'>
              <div className='CompanyForm__field'>
                <h3 className='CompanyForm__field_title'>Company size</h3>
                <div className='CompanyForm__select-wrapper'>
                  <AsyncSelect
                    name="company_size"
                    value={companySizes.find(
                      (option) => option.value === company.company_size
                    )}
                    onChange={handleCompanySizeChange}
                    loadOptions={loadOptions(companySizes)}
                    defaultOptions
                    cacheOptions
                  />
                </div>
              </div>
              <div className='CompanyForm__field'>
                <h3 className='CompanyForm__field_title'>Address</h3>
                <input
                  className='CompanyForm__input'
                  name='address'
                  value={company.address || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='CompanyForm__row'>
              <div className='CompanyForm__field'>
                <h3 className='CompanyForm__field_title'>Zip</h3>
                <input
                  className='CompanyForm__input'
                  name='zip_code'
                  value={company.zip_code || ''}
                  onChange={handleChange}
                />
              </div>
              <div className='CompanyForm__field'>
                <h3 className='CompanyForm__field_title'>VAT registration</h3>
                <input
                  className='CompanyForm__input'
                  name='VAT_registration'
                  value={company.VAT_registration || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="CompanyForm__section">
            <div>
              <div className='CompanyForm__field'>
                <h3 className='CompanyForm__field_title'>VAT registration</h3>
                <input
                  className='CompanyForm__input'
                  name='VAT_registration'
                  value={company.VAT_registration || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <div className='CompanyForm__field'>
                <h3 className='CompanyForm__field_title'>Account currency</h3>
                <div className='CompanyForm__select-wrapper'>
                  <AsyncSelect
                    name="account_currency"
                    value={currencies.find((option) => option.value === company.account_currency)}
                    onChange={handleCurrencyChange}
                    loadOptions={loadOptions(currencies)}
                    defaultOptions
                    cacheOptions
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        maxHeight: '250px',
                        overflowY: 'auto',
                      }),
                      menuPortal: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                  />
                </div>
              </div>
            </div>
            <div>
              <h2 className='CompanyForm__title'>Personal Info</h2>
              <div className='CompanyForm__section'>
                <div className='CompanyForm__row'>
                  <div className='CompanyForm__field'>
                    <h3 className='CompanyForm__field_title'>First name</h3>
                    <input
                      className='CompanyForm__input'
                      name='first_name'
                      value={company.first_name || ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}


export default CompanyForm;