import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ProductsType } from './ProductsType/ProductsType';
import { ProductsFeed } from './ProductsFeed/ProductsFeed';
import { Reseller } from '../HomePage/Reseller/Reseller'
import { useUser } from '../../context/UserContext';

export const ProductsPage = () => {
  const { user } = useUser();
  const location = useLocation();
  const [selectedType, setSelectedType] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    countries: [],
    ratings: [],
    types: []
  });
  
  // Determine the user type (buyer or seller) based on the user context
  const userType = user?.company?.type_of_business === 'brand' ? 'buyer' : 'seller';

  // Set initial filters if coming from search
  useEffect(() => {
    if (location.state?.searchQuery) {
      // Apply the search query as a filter
      const newFilters = { ...selectedFilters };
      
      // Update filters based on search query
      // This would need to be adjusted based on your actual filter structure
      if (location.state.searchQuery) {
        // For example, you might want to set a name filter
        newFilters.searchQuery = location.state.searchQuery;
      }
      
      setSelectedFilters(newFilters);
    }
  }, [location.state]);

  const handleTypeSelect = (type) => {
    setSelectedType(type);
  };
  
  const handleSelectedFilters = (filters) => {
    setSelectedFilters(filters)
  }
  
  return (
    <>
      <ProductsType
        onSelectType={handleTypeSelect}
        selectedType={selectedType}
        onSelectFilters={handleSelectedFilters}
        selectedFilters={selectedFilters}
      />
      <ProductsFeed
        selectedType={selectedType}
        selectedFilters={selectedFilters}
      />
      <Reseller selected={userType} />
    </>
  )
}

export default ProductsPage;