import { requestInstance } from './AuthService/AccessTokenUpdateSevice';
import { BASE_URL } from '../api/constants';

/**
 * Search for products with optional filtering
 * @param {string} query - Search query
 * @param {Object} filters - Additional filters (category, location, etc.)
 * @returns {Promise} - Promise with search results
 */
export const searchProducts = async (query, filters = {}) => {
  try {
    // Build query parameters
    const params = new URLSearchParams();
    if (query) params.append('name', query);
    
    // Add filters
    if (filters.category) params.append('category', filters.category);
    
    // Temporarily only enable location filtering for simple searches
    // to avoid complex filtersets that might cause 500 errors
    if (filters.location && !filters.category) {
      params.append('countries', filters.location);
    }
    
    console.log('Searching products with params:', params.toString());
    
    // Remove duplicate api/ prefix - BASE_URL already includes it
    const url = `${BASE_URL}products/?${params.toString()}`;
    console.log('Request URL:', url);
    
    const response = await requestInstance.get(url);
    console.log('Search API response status:', response.status);
    
    // Ensure we're handling the response correctly even if empty
    if (!response || !response.data) {
      console.warn('Empty response received from product search');
      return { results: [], count: 0 };
    }
    
    // Debug log the actual response structure to help diagnose issues
    console.log('Response structure:', {
      hasResults: !!response.data.results,
      resultsCount: response.data.results ? response.data.results.length : 0,
      hasCount: 'count' in response.data,
      count: response.data.count
    });
    
    return response.data;
  } catch (error) {
    console.error('Error searching products:', error);
    console.error('Error details:', error.response ? error.response.data : 'No response data');
    // Return an empty result structure so UI can handle it gracefully
    return { results: [], count: 0 };
  }
};

/**
 * Search for buyers with optional filtering
 * @param {string} query - Search query
 * @param {Object} filters - Additional filters (location, etc.)
 * @returns {Promise} - Promise with search results
 */
export const searchBuyers = async (query, filters = {}) => {
  try {
    // Build query parameters
    const params = new URLSearchParams();
    if (query) params.append('search', query);
    
    // Add filters - for buyer search, the backend expects 'location' parameter
    // This maps to the country filter in the backend API
    if (filters.location) params.append('location', filters.location);
    
    console.log('Searching buyers with params:', params.toString());
    
    // Remove duplicate api/ prefix - BASE_URL already includes it
    const url = `${BASE_URL}company/buyers/?${params.toString()}`;
    console.log('Request URL:', url);
    
    const response = await requestInstance.get(url);
    console.log('Buyer search API response:', response);
    
    // Return just the data, not the full response object
    // This makes the return format consistent between both search functions
    return response.data || { results: [], count: 0 };
  } catch (error) {
    console.error('Error searching buyers:', error);
    console.error('Error details:', error.response ? error.response.data : 'No response data');
    // Return an empty result structure so UI can handle it gracefully
    return { results: [], count: 0 };
  }
};

/**
 * Get search results based on user type
 * @param {string} userType - Type of user (brand, buyer, unregistered)
 * @param {string} query - Search query
 * @param {Object} filters - Additional filters
 * @returns {Promise} - Promise with search results
 */
export const getSearchResults = async (userType, query, filters = {}) => {
  try {
    console.log(`Getting search results for userType: ${userType}, query: ${query}, filters:`, filters);
    
    if (userType === 'brand') {
      return await searchBuyers(query, filters);
    } else {
      return await searchProducts(query, filters);
    }
  } catch (error) {
    console.error('Error in getSearchResults:', error);
    return { results: [], count: 0 };
  }
};

// Add function to fetch buyer countries
export const getBuyerCountries = async () => {
  try {
    // Remove duplicate api/ prefix - BASE_URL already includes it
    const url = `${BASE_URL}company/buyer-countries/`;
    const response = await requestInstance.get(url);
    return response.data || []; // Should be an array of country strings
  } catch (error) {
    console.error('Error fetching buyer countries:', error);
    // Return empty array for error handling
    return []; 
  }
};

/**
 * Fetches the list of categories that currently have products associated with them.
 * @returns {Promise<Array>} - Promise resolving to an array of category objects or empty array on error.
 */
export const getAvailableCategories = async () => {
  try {
    const url = `${BASE_URL}products/categories/?with_products=true`;
    console.log('Fetching available categories from:', url);
    const response = await requestInstance.get(url);

    // The categories are expected in the 'results' array of the response data
    if (response && response.data && response.data.results) {
      console.log('Available categories received:', response.data.results);
      return response.data.results;
    } else {
      console.warn('No results found when fetching available categories', response);
      return [];
    }
  } catch (error) {
    console.error('Error fetching available categories:', error);
    console.error('Error details:', error.response ? error.response.data : 'No response data');
    return []; // Return empty array for graceful error handling in UI
  }
};
