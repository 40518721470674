import React, { useState, useEffect } from 'react';
import { useSearch } from '../../context/SearchContext';
import { useUser } from '../../context/UserContext';
import { getSearchResults, getBuyerCountries } from '../../services/SearchService';
import './SearchBar.scss';

export const SearchBar = ({ onResultsUpdate = () => {} }) => {
  const { user } = useUser();
  const { 
    searchQuery, 
    setSearchQuery, 
  } = useSearch();
  
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [availableLocations, setAvailableLocations] = useState([]); 
  const [searchError, setSearchError] = useState(null);
  
  const userType = user ? user.company?.type_of_business : 'unregistered';

  useEffect(() => {
    const fetchCountries = async () => {
      if (userType === 'brand') {
        const countries = await getBuyerCountries();
        setAvailableLocations(countries || []);
      }
    };
    fetchCountries();
  }, [userType]); 

  useEffect(() => {
    const handleClickOutside = (event) => {
      const searchBar = document.querySelector('.search-bar');
      if (searchBar && !searchBar.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  
  const getSearchPlaceholder = () => {
    if (!isExpanded) {
      return 'Search';
    }
    switch (userType) {
      case 'brand':
        return 'Buyers';
      case 'buyer':
      case 'unregistered':
      default:
        return 'Products';
    }
  };

  const getLocationPlaceholder = () => {
    return 'From where?';
  };

  const performSearch = async () => {
    if (!searchQuery.trim()) return;
    
    setIsSearching(true);
    setSearchError(null);
    onResultsUpdate({ loading: true, results: [], error: null, type: userType });
    
    try {
      // Build filters dynamically based on userType
      const filters = {};
      if (selectedCategory) {
        filters.category = selectedCategory;
      }
      
      // Add location filter based on user type
      if (selectedLocation) {
        filters.location = selectedLocation;
      }
      
      console.log(`Performing ${userType} search with query: "${searchQuery}" and filters:`, filters);
      
      // Make the search API call with additional debugging
      console.time('search-request');
      const searchResults = await getSearchResults(userType, searchQuery, filters);
      console.timeEnd('search-request');
      
      console.log('Search results received:', searchResults);
      
      // Validation of the response structure
      if (!searchResults) {
        throw new Error('No search results returned from API');
      }
      
      // Access the results directly from the data (now consistently structured)
      const results = searchResults?.results || [];
      
      console.log(`Received ${results.length} search results`);
      
      // Debug log each result (first 5 to avoid console flooding)
      results.slice(0, 5).forEach((result, index) => {
        console.log(`Result ${index + 1}:`, {
          id: result.id,
          name: result.name,
          // Add other key fields that should be present
        });
      });
      
      // Always pass the query to the results component for messaging
      onResultsUpdate({ 
        loading: false, 
        results: results, 
        error: null, 
        type: userType,
        count: searchResults?.count || 0,
        query: searchQuery // Pass the query string
      });
    } catch (error) {
      console.error('Search error details:', error);
      setSearchError(error.message || 'Error performing search');
      onResultsUpdate({ 
        loading: false, 
        results: [], 
        error: error, 
        type: userType,
        count: 0,
        query: searchQuery // Pass the query even for error states
      });
    } 
    setIsSearching(false);
  };

  const handleSearch = () => {
    performSearch();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleClick = () => {
    setIsExpanded(true);
  };

  return (
    <div 
      className={`search-bar ${isExpanded ? 'expanded' : ''}`}
      onClick={handleClick}
    >
      <div className="search-bar__input-group">
        {userType !== 'brand' && (
          <>
            <div className="search-bar__section">
              <span className="search-bar__label">Alcohol categorie</span>
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="search-bar__select"
              >
                <option value="">Select category</option>
                <option value="wine">Wine</option>
                <option value="spirits">Spirits</option>
                <option value="beer">Beer</option>
              </select>
            </div>
            <div className="search-bar__divider" />
          </>
        )}
        
        <div className="search-bar__section">
          <div className="search-bar__input-wrapper">
            <svg className="search-bar__input-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <circle cx="11" cy="11" r="8" />
              <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder={getSearchPlaceholder()}
              className="search-bar__input"
              disabled={isSearching}
            />
            <button
              className={`search-bar__button ${isSearching ? 'searching' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                handleSearch();
              }}
              disabled={isSearching}
            >
              {isSearching ? (
                <span>Searching...</span>
              ) : (
                <>
                  <svg className="search-bar__button-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <circle cx="11" cy="11" r="8" />
                    <line x1="21" y1="21" x2="16.65" y2="16.65" />
                  </svg>
                  <span>Search</span>
                </>
              )}
            </button>
          </div>
        </div>
        
        <div className="search-bar__divider" />
        
        <div className="search-bar__section">
          <span className="search-bar__label">Location</span>
          <select
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            className="search-bar__select"
          >
            <option value="">{getLocationPlaceholder()}</option>
            {userType === 'brand' ? (
              availableLocations.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))
            ) : (
              // Static list of common countries for product search
              <>
                <option value="United States">United States</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="France">France</option>
                <option value="Germany">Germany</option>
                <option value="Italy">Italy</option>
                <option value="Spain">Spain</option>
                <option value="Netherlands">Netherlands</option>
              </>
            )}
          </select>
        </div>
      </div>
      
      {searchError && (
        <div className="search-bar__error-message">
          Error: {searchError}
        </div>
      )}
    </div>
  );
};

export default SearchBar;