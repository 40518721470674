import { useParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { BASE_URL } from '../../api/constants';
import { requestInstance } from '../../services/AuthService/AccessTokenUpdateSevice'
import { CoctailHeaderBattons } from './CoctailHeaderBattons/CoctailHeaderBattons'
import { CocatilDetail } from './CocatilDetail/CocatilDetail'
import { CocatilSideBar } from './CocatilSideBar/CocatilSideBar'
import { RelatedCocktails} from './RelatedCocktails/RelatedCocktails'
import { Poster } from '../HomePage/Poster/Poster'

const LoadingIndicator = () => <div>Loading...</div>;
export const CocktailsDetailPage = () => {
  const [currentCocktail, setCurrentCocktail] = useState(null);
  const [loading, setLoading] = useState(true);
  const { number } = useParams();

  const fetchCocktail = useCallback(async () => {
    try {
      setLoading(true);
      const url = `${BASE_URL}ware-houses/cocktails/${number}`;
      const response = await requestInstance.get(url);
      console.log(response.data);
      setCurrentCocktail(response.data);
    } catch (error) {
      console.error('Failed to fetch cocktail:', error);
    } finally {
      setLoading(false);
    }
  }, [number]);

  useEffect(() => {
    fetchCocktail();
  }, [fetchCocktail]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!currentCocktail) {
    return <div>Cocktail not found</div>;
  }

  return (
    <div>
      <CoctailHeaderBattons currentCocktail={currentCocktail} />
      <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px'}}>
        <CocatilDetail currentCocktail={currentCocktail} onRatingSubmit={fetchCocktail}/>
        <CocatilSideBar />
      </div>
      <RelatedCocktails />
      <Poster selected="buyer" />
    </div>
  )
}

export default CocktailsDetailPage;