import './Reseller.scss';

const RESELLER_DATA = {
  seller: {
    title: 'Find your ideal reseller',
    types: [
      { name: 'Hotels', className: 'Hotels', large: true },
      { name: 'Bars', className: 'Bars' },
      { name: 'Webshop', className: 'Webshop' },
      { name: 'Restaurants', className: 'Restaurants' },
      { name: 'Supermarkets', className: 'Supermarkets' },
      { name: 'Cafes', className: 'Cafes' },
      { name: 'Alcohol Shops', className: 'Alcohol_shops' }
    ]
  },
  buyer: {
    title: 'Find your ideal brand',
    types: [
      { name: 'Spirits', className: 'Hotels', large: true },
      { name: 'Wine', className: 'Bars' },
      { name: 'Beer', className: 'Webshop' },
      { name: 'RTD', className: 'Restaurants' },
      { name: 'Non-Alcoholic', className: 'Supermarkets' },
      { name: 'Mixers', className: 'Cafes' },
      { name: 'Accessories', className: 'Alcohol_shops' }
    ]
  }
};

export const Reseller = ({ selected }) => {
  return (
    <div className="Reseller">
      <h3 className="Reseller__title">{RESELLER_DATA[selected].title}</h3>
      <div className="Reseller__container">
        {RESELLER_DATA[selected].types.map((type, index) => (
          <div 
            key={index} 
            className={`Reseller__type ${type.large ? 'Reseller__type--large' : ''} ${type.className}`}
          >
            <span>{type.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Reseller;